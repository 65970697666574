import PrivateRoute from "components/PrivateRoute"
import Custom from "pages/History/Custom"
import General from "pages/History/General"
import Image from "pages/History/Image"
import PredictDo from "pages/History/PredictDo"
import Layout from "pages/Layout"
import Login from "pages/Login"
import Meterindco from "pages/Meterindco"
import { CreatePredict } from "pages/Predict/CreatePredict"
import CreateTrain from "pages/Predict/CreateTrain"
import { EditPredict } from "pages/Predict/EditPredict"
import { EditTrain } from "pages/Predict/EditTrain"
import Predict from "pages/Predict/Predict"
import { PredictChart } from "pages/Predict/PredictChart"
import Train from "pages/Predict/Train"
import Realtime from "pages/Realtime"
import Area from "pages/Setting/Area/Area"
import CreateArea from "pages/Setting/Area/CreateArea"
import CreateDevice from "pages/Setting/Area/CreateDevice"
import EditArea from "pages/Setting/Area/EditArea"
import EditDevice from "pages/Setting/Area/EditDevice"
import ViewChart from "pages/Setting/Area/ViewChart"
import Vendor from "pages/Setting/Vendor"
import Dosage from "pages/Setting/Dosage"
import CreateEvent from "pages/Setting/Event/CreateEvent"
import EditEvent from "pages/Setting/Event/EditEvent"
import Event from "pages/Setting/Event/Event"
import CreateGroupV2 from "pages/Setting/Group/CreateGroupV2"
import Group from "pages/Setting/Group/Group"
import Instrument from "pages/Setting/Instrument/Instrument"
import LogViewer from "pages/Setting/LogViewer"
import CreateManager from "pages/Setting/Manager/CreateManager"
import EditManager from "pages/Setting/Manager/EditManager"
import Manager from "pages/Setting/Manager/Manager"
import Regulation from "pages/Setting/Regulation"
import TokenSetting from "pages/Setting/TokenSetting"
import UsageRecord from "pages/Setting/UsageRecord"
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import EditGroupV2 from "pages/Setting/Group/EditGroupV2"

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  })

  // useEffect(() => {
  //   authService.setProfile();
  // }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Realtime />} />
              <Route path="/realtime" element={<Realtime />} />
              <Route path="/meterindco" element={<Meterindco />} />

              <Route path="/predict/train-model" element={<Train />} />
              <Route path="/predict/train-model/create" element={<CreateTrain />} />
              <Route path="/predict/train-model/:id/edit" element={<EditTrain />} />
              <Route path="/predict/predict-model" element={<Predict />} />
              <Route path="/predict/predict-model/create" element={<CreatePredict />} />
              <Route path="/predict/predict-model/:id/chart" element={<PredictChart />} />
              <Route path="/predict/predict-model/:id/edit" element={<EditPredict />} />

              <Route path="/history/general" element={<General />} />
              <Route path="/history/custom" element={<Custom />} />
              <Route path="/history/image" element={<Image />} />
              <Route path="/history/predict-do" element={<PredictDo />} />

              <Route path="/setting/event" element={<Event />} />
              <Route path="/setting/event/create" element={<CreateEvent />} />
              <Route path="/setting/event/:id/edit" element={<EditEvent />} />
              <Route path="/setting/area" element={<Area />} />
              <Route path="/setting/area/create" element={<CreateArea />} />
              <Route path="/setting/area/:id/edit" element={<EditArea />} />
              <Route path="/setting/area/:id/edit/device" element={<CreateDevice />} />
              <Route
                path="/setting/area/:id/edit/device/:deviceId/chart/"
                element={<ViewChart />}
              />
              <Route
                path="/setting/area/:id/edit/device/:deviceId/edit/"
                element={<EditDevice />}
              />
              <Route path="/setting/instrument" element={<Instrument />} />

              <Route path="/setting/manager" element={<Manager />} />
              <Route path="/setting/manager/create" element={<CreateManager />} />
              <Route path="/setting/manager/:id/edit" element={<EditManager />} />
              <Route path="/setting/group" element={<Group />} />
              {/* <Route path="/setting/group/:id/edit" element={<EditGroup />} /> */}
              <Route path="/setting/group/:id/edit" element={<EditGroupV2 />} />
              <Route path="/setting/usage-record" element={<UsageRecord />} />
              {/* <Route path="/setting/group/create" element={<CreateGroup />} /> */}
              <Route path="/setting/group/create" element={<CreateGroupV2 />} />
              <Route path="/setting/dosage" element={<Dosage />} />
              <Route path="/setting/token-setting" element={<TokenSetting />} />
              <Route path="/setting/regulation" element={<Regulation />} />
              <Route path="/setting/log-viewer" element={<LogViewer />} />
              <Route path="/setting/vendor" element={<Vendor />} />
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
      <ToastContainer />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  )
}

export default App
