import { getUserLogs } from "features/utils"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"

function useUserLog() {
    const { isLoading, isError, error, isFetching, data } = useQuery("getUserLogs", () => getUserLogs())

    const [logs, setLogs] = useState(null)

    useEffect(() => {
        setLogs(data?.data)
    }, [data])

    return [logs, setLogs, isLoading, isError, error, isFetching]
}

export default useUserLog
