import Breadcrumbs from "@mui/material/Breadcrumbs"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"

function BreadCrumb({ data }) {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      className="py-2"
    >
      {data}
    </Breadcrumbs>
  )
}

export default BreadCrumb
