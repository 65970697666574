function AddButton({ pageNavigate, text = "" }) {
  return (
    <button
      onClick={pageNavigate}
      className="bg-primary-600 hover:bg-primary-800 text-white font-bold py-1 px-3 border border-primary-300 rounded my-3 flex"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
      </svg>
      新增{text}
    </button>
  )
}

export default AddButton
