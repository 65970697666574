import { newHttpInstance } from "features/utils/common/http"

const API_URL = "/api/v1/"

// Get Area
export async function getArea(params) {
  try {
    const searchParams = new URLSearchParams(params);
    const instance = newHttpInstance("GET", `${API_URL}areas?include=metadata&per_page=20&page=1&${searchParams}`)
    const res = await instance.request()
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Get Area Specific
export async function getAreaSpecific(id = "") {
  try {
    const instance = newHttpInstance("GET", `${API_URL}areas/${id}`)
    const res = await instance.request()
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Get Device Id by Area
export async function getDeviceId(id = "") {
  try {
    const instance = newHttpInstance("GET", `${API_URL}device?area_id=${id}`)
    const res = await instance.request()
    return res
  } catch (error) {
    throw new Error(error)
  }
}


// Post Area
export async function postArea(data = {}) {
  try {
    const instance = newHttpInstance("POST", `${API_URL}areas`)
    const res = await instance.request({
      data,
    })
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// PUT Area
export async function putArea(data = {}, id = "") {
  try {
    const instance = newHttpInstance("PUT", `${API_URL}areas/${id}`)
    const res = await instance.request({
      data,
    })
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// DELETE Area
export async function deleteArea(ids = {}) {
  try {
    const instance = newHttpInstance("DELETE", `${API_URL}areas`)
    const res = await instance.request({
      data: ids,
    })
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Get Vendor
export async function getVendor() {
  try {
    const instance = newHttpInstance("GET", `${API_URL}vendors`)
    const res = await instance.request()
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Get Vendor Setting
export async function getVendorSettingTypes() {
  try {
    const instance = newHttpInstance("GET", `${API_URL}vendor-setting/category/types?categories=1,2`)
    const res = await instance.request()
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Get User Logs
export async function getUserLogs() {
  try {
    const instance = newHttpInstance("GET", `${API_URL}user-logs`)
    const res = await instance.request()
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Get Database
export async function getDatabase() {
  try {
    const instance = newHttpInstance("GET", `${API_URL}db-size`)
    const res = await instance.request()
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Post Device
export async function postDevice(data = {}) {
  try {
    const instance = newHttpInstance("POST", `${API_URL}device`)
    const res = await instance.request({
      data,
    })
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Delete Device
export async function deleteDevice(ids = {}) {
  try {
    const instance = newHttpInstance("DELETE", `${API_URL}device`)
    const res = await instance.request({
      data: ids,
    })
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Get Device Chart Data
export async function getChart(id = "", threshold = false) {
  try {
    let url = `${API_URL}device/${id}`
    if (threshold) {
      url += '?threshold=true'
    }
    const instance = newHttpInstance("GET", url)
    const res = await instance.request()
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Get Device Chart Table Data
export async function getChartTable(id = "", beginTime = "2020-07-01", endTime = "2022-07-30") {
  try {
    const instance = newHttpInstance(
      "GET",
      `${API_URL}device-data/${id}?begin_time=${beginTime}&end_time=${endTime}`
    )
    const res = await instance.request()
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// PUT Sensor
export async function putSensor(data = {}, id = "") {
  try {
    const instance = newHttpInstance("PUT", `${API_URL}device/${id}`)
    const res = await instance.request({
      data,
    })
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Get Profile
export async function getProfile() {
  const instance = newHttpInstance("GET", `${API_URL}auth/profile`)
  const res = await instance.request()
  return res
}

// Get Permission
export async function getPermissions() {
  try {
    const instance = newHttpInstance("GET", `${API_URL}permissions`)
    const res = await instance.request()
    return res.data
  } catch (error) {
    throw new Error(error)
  }
}

// Get Roles
export async function getRoles() {
  try {
    const instance = newHttpInstance("GET", `${API_URL}roles`)
    const res = await instance.request()
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Get Roles By ID
export async function getRolesById(id = "") {
  try {
    const instance = newHttpInstance("GET", `${API_URL}roles/${id}`)
    const res = await instance.request()
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Post Role
export async function postRole(data = {}) {
  try {
    const instance = newHttpInstance("POST", `${API_URL}roles`)
    const res = await instance.request({
      data,
    })
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Delete Role
export async function deleteRoles(data) {
  const instance = newHttpInstance("DELETE", `${API_URL}roles`)
  const res = await instance.request({
    data,
  })
  return res
}

// PUT Role
export async function putRoles(data = {}, id = "") {
  const instance = newHttpInstance("PUT", `${API_URL}roles/${id}`)
  const res = await instance.request({
    data,
  })
  return res
}

// Get Admins
export async function getAdmins() {
  try {
    const instance = newHttpInstance("GET", `${API_URL}admins`)
    const res = await instance.request()
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Get Admins By ID
export async function getAdminById(id = "") {
  try {
    const instance = newHttpInstance("GET", `${API_URL}admins/${id}`)
    const res = await instance.request()
    return res
  } catch (error) {
    throw new Error(error)
  }
}

// Post Admin
export async function postAdmin(data = {}) {
  const instance = newHttpInstance("POST", `${API_URL}admins`)
  const res = await instance.request({
    data,
  })
  return res
}

// Delete Admin
export async function deleteAdmin(ids) {
  const instance = newHttpInstance("DELETE", `${API_URL}admins`)
  const res = await instance.request({
    data: { ids },
  })
  return res
}

// PUT Admin
export async function putAdmin(data = {}, id = "") {
  const instance = newHttpInstance("PUT", `${API_URL}admins/${id}`)
  const res = await instance.request({
    data,
  })
  return res
}

// GET Vendor Metrics
export async function getVendorMetrics(areas) {
  try {
    const instance = newHttpInstance("GET", `${API_URL}vendor-metrics/${areas}`)
    const res = await instance.request()
    return res.data
  } catch (error) {
    throw new Error(error)
  }
}
