import { TextField, FormControlLabel, Switch } from "@mui/material"
import { Controller } from "react-hook-form"

function Quadlink(props) {
    const { control } = props

    return (
        <div className="mt-8">
            <Controller
                render={({ field }) => <TextField
                    {...field} fullWidth variant="outlined" label="感測器 ID"
                />}
                name="data.deviceid"
                control={control}
                defaultValue=""
            />
        </div>
    )
}

export default Quadlink
