// import logo from "../assets/png/logo.png"
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Spinner from "components/Spinner"
import { login, reset } from "../features/auth/authSlice"

function Login() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { username, password } = formData

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      username,
      password,
    }

    dispatch(login(userData))
  }

  const { user, isLoading, isSuccess, isError, message } = useSelector((state) => state.auth)

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (isSuccess && user) {
      navigate("/realtime")
    }

    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])

  if (isLoading) {
    return <Spinner />
  }

  const envValue = process.env.REACT_APP_TITLE

  return (
    <div className="bg-[url('https://images.unsplash.com/photo-1464925257126-6450e871c667?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80')] bg-cover w-full bg-center h-screen">
      {/* <img src={logo} className="mx-auto w-60 lg:w-1/5 pt-10" alt="logo" /> */}
      <h1 className="pt-40 text-center text-white lg:text-3xl text-2xl px-2">{process.env.REACT_APP_TITLE}</h1>
      <section className="w-2/3 lg:w-3/12 mx-auto">
        <form onSubmit={onSubmit} className="mt-12">
          <div>
            <input
              onChange={onChange}
              type="text"
              id="username"
              name="username"
              placeholder="帳號"
              className="block w-full rounded-md bg-primary-100 focus:outline-none focus:ring focus:ring-opacity-75 focus:ring-primary-600 px-4 py-2 mb-4"
              required
            />
          </div>
          <div>
            <input
              onChange={onChange}
              type="password"
              id="password"
              name="password"
              placeholder="密碼"
              className="block w-full rounded-md bg-primary-100 focus:outline-none focus:ring focus:ring-opacity-75 focus:ring-primary-600 px-4 py-2"
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary-600 rounded-md hover:bg-primary-300 focus:outline-none focus:bg-primary-300 mt-12"
            >
              登入
            </button>
          </div>
        </form>
        {envValue === "智慧養殖跨場域資料庫分析平台" && (
          <div className="mt-5 text-white font-bold">請使用 AI User 帳密登入: aiuser / aiuser</div>
        )}
      </section>
    </div>
  )
}

export default Login
