import { useEffect, useState, useCallback } from "react"
import { useParams, Link, useNavigate } from "react-router-dom"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import Tabs from "@mui/material/Tabs"
import TabPanel from "@mui/lab/TabPanel"
import { getAreaSpecific, putArea } from "features/utils"
import { toast } from "react-toastify"
import CustomDialog from "components/dialog/CustomDialog"
import BreadCrumb from "components/BreadCrumb"
import Spinner from "components/Spinner"
import { useQuery } from "utils/Query"
import AreaForm from "./Form/AreaForm"
import Device from "./Device"
import { AIOT } from "./AIOT"

const defaultValues = {
  "name": "",
  "description": "",
  "owner": "",
  "club": "",
  "fish_type": 0,
  "fish_special": "",
  "fish_mix": "",
  "stage": 0,
  "other_stage": "",
  "fishpond": 0,
  "other_fishpond": "",
  "structure": 0,
  "area": "",
  "water_volume": "",
  "depth": "",
  "bred_amount": "",
  "capacity": "",
  'city': '',
  'district': '',
  'address': '',
  "latitude": 0,
  "longitude": 0,
}

function EditArea() {

  // params
  const query = useQuery()
  const params = useParams()
  const areaId = params.id

  // settings
  const navigate = useNavigate()
  const [value, setValue] = useState("1")
  const [open, setOpen] = useState(false)
  const [formValues, setFormValues] = useState(defaultValues)
  const [submitData, setSubmitData] = useState(defaultValues)
  const [isLoading, setIsLoading] = useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (event, newValue) => { // Change TabList
    setValue(newValue)
  }

  // first time render
  const handleFetchArea = useCallback(() => {
    setIsLoading(true)
    getAreaSpecific(areaId).then(resp => {
      const queryData = resp.data
      setFormValues({
        "name": queryData.name,
        "description": queryData.description,
        "owner": queryData.owner,
        "club": queryData.club,
        "fish_type": queryData.fish_type,
        "stage": queryData.stage,
        "fishpond": queryData.fishpond,
        "structure": queryData.structure,
        "fish_special": queryData.data?.fish.special,
        "fish_mix": queryData.data?.fish.mix,
        "other_stage": queryData.data?.other_stage,
        "other_fishpond": queryData.data?.other_fishpond,
        "area": queryData.data?.area,
        "water_volume": queryData.data?.water_volume,
        "depth": queryData.data?.depth,
        "bred_amount": queryData.data?.bred_amount,
        "capacity": queryData.data?.capacity,
        "city": queryData.city,
        "district": queryData.district,
        "address": queryData.address,
        "latitude": queryData.data.latitude,
        "longitude": queryData.data.longitude,
      })
    }).catch(err => {
      toast.error(err.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    handleFetchArea()
  }, [])

  useEffect(() => {
    if (query.get('target') === '1') {
      setValue('1')
    }

    if (query.get('target') === '2') {
      setValue('2')
    }
  }, [query])

  // update the record
  const handleUpdateArea = useCallback((submitData, areaId) => {
    putArea(submitData, areaId).then(resp => {
      toast.success('成功')
    }).catch(err => toast.error(err.message)).finally(() => {
      handleFetchArea()
      setOpen(false)
    })
  }, [])

  const onEditSubmit = (data) => {
    if (!open) {
      setOpen(true)
      setSubmitData(data)
      return
    }

    handleUpdateArea(submitData, areaId)
  }

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      系統設定
    </Typography>,
    <Typography key="2" color="text.primary">
      <Link to="/setting/area">設定場域</Link>
    </Typography>,
    <Typography key="3" color="text.secondary">
      修改場域
    </Typography>,
  ]

  return (
    <>

      {
        isLoading ? <Spinner /> :
          <>
            <h1 className="text-2xl font-bold my-3">修改場域</h1>
            <BreadCrumb data={breadcrumbs} />
            <Box sx={{ width: "100%", typography: "body1" }} className="my-3">
              <TabContext value={value}>
                <Tabs
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                  value={value}
                  onChange={handleChange}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="secondary tabs example"
                >
                  <Tab label="Info" value="1" />
                  <Tab label="Device" value="2" />
                  <Tab label="AIOT" value="3" />
                </Tabs>
                <TabPanel value="1">
                  <AreaForm values={formValues} onSubmit={onEditSubmit} />
                </TabPanel>
                <TabPanel value="2">
                  <Device areaId={areaId} />
                </TabPanel>
                <TabPanel value="3">
                  <AIOT areaId={areaId} />
                </TabPanel>
              </TabContext>
            </Box>
          </>
      }
      <CustomDialog handleClose={handleClose} method={onEditSubmit} open={open} content="修改" />

    </>
  )
}

export default EditArea
