import { nanoid } from "nanoid"
import Box from "@mui/material/Box"
import { LineChart } from "components/chart/LineChart"
import { NavLink } from 'react-router-dom';
import { useSelector } from "react-redux";

export function ChartGroup({ title, data, deviceId, areaId }) {
    const { ownPermissionList } = useSelector((state) => state.ownPermission)
    return (
        <div className="relative px-4 py-5 mb-6 overflow-hidden overflow-x-auto rounded-lg bg-slate-50 drop-shadow-lg whitespace-nowrap">
            <h1 className="pb-2 mt-0 text-2xl font-medium leading-tight text-primary-800">
                {title}
            </h1>
            {
                ownPermissionList.includes("AREA_UPDATE") && <NavLink to={`/setting/area/${areaId}/edit/device/${deviceId}/edit?target=2`} target="_blank" className="px-4 py-2 mb-8 font-bold text-white bg-blue-400 rounded w-22 hover:bg-blue-300 borde align-center" >警示閥值</NavLink>
            }
            <Box sx={{ display: "flex", justifyContent: "start" }}>
                {data?.map((data) =>
                    <LineChart key={nanoid()} chart={data} />
                )}
            </Box>
        </div>
    )
}   