import { Typography } from "@mui/material";
import BreadCrumb from "components/BreadCrumb";
import { getPredictChart } from "features/utils/predict-model";
import MUIDataTable from "mui-datatables";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CartesianGrid, Line, XAxis, YAxis, LineChart, Tooltip } from "recharts";

export function PredictChart() {

    const params = useParams()
    const { id } = params
    const [data, setData] = useState([])

    const breadcrumbs = [
        <Typography key="1" color="text.primary">
            AI 預測模型
        </Typography>,
        <Typography key="2" color="text.secondary">
            預測圖表
        </Typography>,
    ]

    const fetchData = useCallback((id) => {
        getPredictChart(id).then(resp => setData(resp.data)).catch(() => toast.error('抓取資料失敗'))
    }, [])

    const options = {
      responsive: "standard",
      print: false,
      download: false,
      search: true,
      filter: true,
      filterArrayFullMatch: false,
      filterType: "checkbox",
      elevation: 1,
      rowsPerPage: 5,
      rowsPerPageOptions: [5, 10, 50],
      selectableRowsHideCheckboxes: false,
    }

    const columns = [
        {
            name: "predict_time",
            label: "事件時間",
        },
        // {
        //     name: "device_name",
        //     label: "模型裝置",
        // },
        {
            name: "model",
            label: "模型名稱",
        },
        {
            name: "nopca_predict_temp",
            label: "nopca_predict_temp",
        },
        {
            name: "pca_predict_temp",
            label: "pca_predict_temp",
        },
        {
            name: "raw_temp",
            label: "raw_temp",
        },
        {
            name: "ui_time",
            label: "ui_time",
        }
    ];

    useEffect(() => {
        if (id === undefined) {
            return
        }
        fetchData(id)
    }, [id])

    return (
        <>
            <h1 className="my-3 text-2xl font-bold">預測圖表</h1>
            <BreadCrumb data={breadcrumbs} />
            <LineChart width={950} height={350} data={data} className="my-4">
                <YAxis type="number" orientation="left" yAxisId={1} tick={{ fill: "#387908" }} />
                <YAxis type="number" orientation="left" yAxisId={2} tick={{ fill: "#38abc8" }} />
                <YAxis type="number" orientation="left" yAxisId={2} tick={{ fill: "#ff7777" }} />

                <XAxis dataKey="predict_time" interval={10} />
                <Tooltip position={{ y: 200 }} />
                <CartesianGrid stroke="#f5f5f5" />

                <Line dataKey="nopca_predict_temp" stroke="#387908" strokeWidth={2} yAxisId={1} />
                <Line dataKey="pca_predict_temp" stroke="#38abc8" strokeWidth={2} yAxisId={2} />
                <Line dataKey="raw_temp" stroke="#ff7777" strokeWidth={2} yAxisId={2} />
            </LineChart>
            <MUIDataTable title="裝置列表" data={data} columns={columns} options={options} />
        </>
    )
}