import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Dialog from "@mui/material/Dialog"
import Switch from '@mui/material/Switch';
import { colorArray, defaultCreateAt } from "./Setting"

export function SwitchAreaButton({ selectedFields, fieldSelected, handleCloseFieldSelected, handleCloseAllSelect, handleOpenAllSelect, handleOnDisplayColumn }) {
    return (
        <Dialog
            open={fieldSelected}
            onClose={handleCloseFieldSelected}
            fullWidth
            maxWidth="lg"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className="p-5">
                <div className="flex">
                    <p className="text-4xl text-gray-900">欄位設定</p>
                </div>
                <div className="flex mt-3 mb-3 gap-2">
                    全部
                    <button
                        onClick={handleCloseAllSelect}
                        className="px-3 py-1 font-bold text-white bg-gray-400 rounded hover:bg-gray-300 align-center"
                    >
                        關閉
                    </button>
                    <button
                        onClick={handleOpenAllSelect}
                        className="px-3 py-1 font-bold text-white bg-blue-400 rounded hover:bg-blue-300 align-center"
                    >
                        開啟
                    </button>
                </div>
                <div className="flex justify-start">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>顯示隔線</TableCell>
                                    <TableCell align="center">顏色</TableCell>
                                    <TableCell align="center">名稱</TableCell>
                                    <TableCell align="center">單位</TableCell>
                                    <TableCell align="center">說明</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedFields.map((selectField, index) => {

                                    if (selectField.field === defaultCreateAt) {
                                        return
                                    }

                                    return (
                                        <TableRow key={selectField.field} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                <div className="flex items-center mb-4">
                                                    <Switch checked={selectField.selected} onChange={handleOnDisplayColumn} value={selectField.field} />
                                                </div>
                                            </TableCell>
                                            <TableCell align="center">{colorArray[index]}</TableCell>
                                            <TableCell align="center">{selectField.label}</TableCell>
                                            <TableCell align="center">{selectField.unit}</TableCell>
                                            <TableCell align="center">{selectField.field}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="flex justify-end mb-5 mt-3">
                    <button
                        onClick={handleCloseFieldSelected}
                        className="px-3 py-1 font-bold text-white bg-red-400 rounded hover:bg-red-300 align-center"
                    >
                        關閉
                    </button>
                </div>
            </div>
        </Dialog>
    )
}