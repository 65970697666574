import { newHttpInstance } from "features/utils/common/http"

const API_URL = "/api/v1/"

export async function getPredictions(params = {}) {
    try {
        const searchParams = new URLSearchParams(params);
        let queryParams = '';
        if (searchParams !== '') {
            queryParams = `?${searchParams}`
        }
        const instance = newHttpInstance("GET", `${API_URL}predict-model${queryParams}`)
        const res = await instance.request()
        return res
    } catch (error) {
        throw new Error(error)
    }
}

export async function getPredictionById(id) {
    try {
        const instance = newHttpInstance("GET", `${API_URL}predict-model/${id}`)
        const res = await instance.request()
        return res
    } catch (error) {
        throw new Error(error)
    }
}

export async function createPredictModel(data) {
    try {
        const instance = newHttpInstance("POST", `${API_URL}predict-model`)
        const res = await instance.request({
            data
        })
        return res
    } catch (error) {
        throw new Error(error)
    }
}

export async function updatePredictModel(id, data) {
    try {
        const instance = newHttpInstance("PUT", `${API_URL}predict-model/${id}`)
        const res = await instance.request({
            data
        })
        return res
    } catch (error) {
        throw new Error(error)
    }
}

export async function deletePredictModel(ids) {
    try {
        const instance = newHttpInstance("DELETE", `${API_URL}predict-model`)
        const res = await instance.request({
            data: { ids }
        })
        return res
    } catch (error) {
        throw new Error(error)
    }
}

export async function getPredictChart(id) {
    try {
        const instance = newHttpInstance("GET", `${API_URL}predict-model/ai-call/${id}`)
        const res = await instance.request()
        return res
    } catch (error) {
        throw new Error(error)
    }
}

export async function uploadFile(file) {
    try {
        const instance = newHttpInstance("POST", `${API_URL}import-file`)
        const res = await instance.request({
            data: file
        })
        return res
    } catch (error) {
        throw new Error(error)
    }
}