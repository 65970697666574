import { TextField, Typography } from "@mui/material"
import BreadCrumb from "components/BreadCrumb"
import { VendorMetrics } from "components/predict-model/VendorMetrics"
import { getVendor } from "features/utils"
import { getVendorAreas, getVendorMetrics } from "features/utils/vendor"
import { nanoid } from "nanoid"
import { useCallback, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { DatePicker } from "components/DatePicker"
import { createPredictModel, getPredictionById, updatePredictModel } from "features/utils/predict-model"
import { PredictForm } from "./PredictForm"

export function EditPredict() {
    const [defaultFormValue, setDefaultFormValue] = useState({
        devices: [],
        features: [],
        targets: [],
    })
    const [csvFile, setCSVFile] = useState(null)
    const { id } = useParams();

    const breadcrumbs = [
        <Typography key="1" color="text.primary">
            AI 預測模型
        </Typography>,
        <Typography key="2" color="text.primary">
            <Link to="/predict/predict-model">預測模式</Link>
        </Typography>,
        <Typography key="2" color="text.secondary">
            編輯預測模式
        </Typography>,
    ]

    const handleFetchPrediction = useCallback(() => {
        getPredictionById(id).then(resp => {
            const { data } = resp
            const newValue = {
                vendor: data.vendor,
                name: data.name,
                model: data.model,
                pca: data.pca !== 0,
                begin_time: data.begin_time,
                end_time: data.end_time,
                devices: data.devices.map(v => v.device_id.toString()),
                features: data.features.map(v => v.name),
                targets: data.targets.map(v => v.name),
                source: `${data.source}`,
                csv_id: data.csv_id
            }
            if (data.source === 1) {
                setCSVFile(data.csv)
            }
            setDefaultFormValue(newValue)
        }).catch(e => toast.error('抓取資料失敗'))
    }, [])

    const onSubmit = (data, e) => {
        updatePredictModel(id, data).then(resp => {
            toast.success('編輯成功')
            handleFetchPrediction();
        }).catch(e => {
            toast.error(e.error)
        })
    }

    useEffect(() => {
        handleFetchPrediction()
    }, [handleFetchPrediction])

    return (
        <PredictForm handleOnSubmit={onSubmit} breadcrumbs={breadcrumbs} defaultValues={defaultFormValue} csvFile={csvFile} />
    )
}