import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getAreas } from "features/utils/area";
import { getDevices } from "features/utils/device";
import moment from "moment";
import { nanoid } from "nanoid";
import { useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { toast } from "react-toastify";

const dateFormat = 'YYYY-MM-DD'

export function RealTimeSearchForm({
    register, control, handleSubmit, areas, devices, selectAreaId, handleSelectArea
}) {

    return (
        <form className="flex justify-start gap-5 mb-5" onSubmit={handleSubmit}>
            <select value={selectAreaId} className="border w-40 inline-block p-3.5" onChange={handleSelectArea}>
                <option key={nanoid()} value="0" >選擇場域</option>
                {
                    areas.map(v => <option key={nanoid()} value={v.id} >{v.name}</option>)
                }
            </select>

            <select className="border w-40 inline-block p-3.5" {...register("device_id")} disabled={devices.length === 0}>
                {
                    devices.map(v => <option key={nanoid()} value={v.id}>{v.name}</option>)
                }
            </select>

            <div className="inline-block mr-5">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                        name="start_time"
                        control={control}
                        defaultValue={moment().format(dateFormat)}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <DesktopDatePicker
                                value={value}
                                label="開始時間"
                                inputFormat="yyyy-MM-dd"
                                onChange={(value) =>
                                    onChange(moment(value).format(dateFormat))
                                }
                                renderInput={(params) => <TextField {...params} />}
                            />
                        )}
                    />
                </LocalizationProvider>
            </div>
            <div className="inline-block mr-5">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                        name="end_time"
                        control={control}
                        defaultValue={moment().format(dateFormat)}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <DesktopDatePicker
                                value={value}
                                label="結束時間"
                                inputFormat="yyyy-MM-dd"
                                onChange={(value) =>
                                    onChange(moment(value).format(dateFormat))
                                }
                                renderInput={(params) => <TextField {...params} />}
                            />
                        )}
                    />
                </LocalizationProvider>
            </div>
            <button
                className="bg-red-400 hover:bg-red-300 text-white font-bold py-1 px-3 borde rounded align-center w-20"
            >
                查詢
            </button>
        </form>
    )
}