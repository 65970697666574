import { TextField, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import BreadCrumb from "components/BreadCrumb"
import { getDevicesHistoryData } from "features/utils/device"
import { getCategoriesVendor, getSpecVendor } from "features/utils/vendor-setting"
import moment from "moment"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { HistoryChart } from "./HistoryChart"

const breadcrumbs = [
  <Typography key="1" color="text.primary">
    歷史資料總覽
  </Typography>,
  <Typography key="2" color="text.secondary">
    自訂查詢
  </Typography>,
]

export default function Custom() {
  const { control, handleSubmit } = useForm()
  const dateFormat = "YYYY-MM-DD"
  const [categoriesDataFirst, setCategoriesDataFirst] = useState("")
  const [categoriesDataSecond, setCategoriesDataSecond] = useState("")
  const [categoriesSpecLeft, setCategoriesSpecLeft] = useState("")
  const [categoriesSpecRight, setCategoriesSpecRight] = useState("")
  const [deviceIdLeft, setDeviceIdLeft] = useState(0)
  const [deviceIdRight, setDeviceIdRight] = useState(0)
  const [chartData, setChartData] = useState(null)

  useEffect(() => {
    getCategoriesVendor(1).then((resp) => {
      setCategoriesDataFirst(resp)
    })
    getCategoriesVendor(2).then((resp) => {
      setCategoriesDataSecond(resp)
    })
  }, [])

  const handleSelectInfoLeft = (e) => {
    const { value } = e.target
    if (value === undefined || value === null) {
      return
    }
    getSpecVendor(value).then((resp) => {
      setCategoriesSpecLeft(resp)
    })
  }

  const handleSelectInfoRight = (e) => {
    const { value } = e.target
    if (value === undefined || value === null) {
      return
    }
    getSpecVendor(value).then((resp) => {
      setCategoriesSpecRight(resp)
    })
  }

  const handleSelectDeviceLeft = (e) => {
    const { value } = e.target
    setDeviceIdLeft(value)
  }

  const handleSelectDeviceRight = (e) => {
    const { value } = e.target
    setDeviceIdRight(value)
  }

  const onSubmit = (data) => {
    if (deviceIdLeft === 0 || deviceIdRight === 0) {
      return
    }
    getDevicesHistoryData({
      begin_time: data.start_time,
      end_time: data.end_time,
      devices: [deviceIdLeft, deviceIdRight]
    }).then(resp => {
      setChartData(resp.data)
    })
  }

  return (
    <>
      <h1 className="my-3 text-2xl font-bold">自訂查詢</h1>
      <BreadCrumb data={breadcrumbs} />

      <div className="flex pb-4 border-b-2 border-b-slate-200">
        <div className="flex-auto">
          <h1 className="py-2 pr-2 my-auto text-lg font-bold">水質資訊</h1>
          <div className="flex pb-4">
            <select className="inline-block border" onChange={handleSelectInfoLeft}>
              <option value="0">選擇水質資訊</option>
              {categoriesDataFirst &&
                categoriesDataFirst.map((v) => (
                  <option key={v.name} value={v.name}>
                    {v.alias}
                  </option>
                ))}
            </select>
            {categoriesSpecLeft && (
              <select className="ml-4 border" onChange={handleSelectDeviceLeft}>
                <option key={0} value={0}>請選擇</option>
                {categoriesSpecLeft.map((v) => (
                  <option key={v.id} value={v.id}>
                    {v.name}
                  </option>
                ))}
              </select>
            )}
          </div>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="start_time"
              control={control}
              defaultValue={moment().format(dateFormat)}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DesktopDatePicker
                  value={value}
                  label="開始時間"
                  inputFormat="yyyy-MM-dd"
                  onChange={(value) => onChange(moment(value).format(dateFormat))}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </LocalizationProvider>
        </div>

        <div className="flex-auto">
          <h1 className="py-2 pr-2 my-auto text-lg font-bold">氣候資訊</h1>
          <div className="flex pb-4">
            <select className="inline-block border" onChange={handleSelectInfoRight}>
              <option value="0">選擇氣候資訊</option>
              {categoriesDataSecond &&
                categoriesDataSecond.map((v) => (
                  <option key={v.name} value={v.name}>
                    {v.alias}
                  </option>
                ))}
            </select>
            {categoriesSpecRight && (
              <select className="ml-4 border" onChange={handleSelectDeviceRight}>
                <option key={0} value={0}>請選擇</option>
                {categoriesSpecRight.map((v) => (
                  <option key={v.id} value={v.id}>
                    {v.name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="flex justify-between">
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  name="end_time"
                  control={control}
                  defaultValue={moment().format(dateFormat)}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <DesktopDatePicker
                      value={value}
                      label="結束時間"
                      inputFormat="yyyy-MM-dd"
                      onChange={(value) => onChange(moment(value).format(dateFormat))}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="my-auto">
              <button
                type="submit"
                className="w-20 px-3 py-1 font-bold text-white bg-red-400 rounded hover:bg-red-300 borde align-center"
                onClick={handleSubmit(onSubmit)}
              >
                查詢
              </button>
            </div>
          </div>
        </div>
      </div>
      {chartData !== null && <HistoryChart respData={chartData} />}
    </>
  )
}
