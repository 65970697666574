import React, { useEffect, useState } from "react"
import Header from "components/Header"
import Sidebar from "components/Sidebar"
import { Outlet, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

/* For New Page */
function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const now = new Date()
  const year = now.getFullYear()

  return (
    <section className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content Area */}
      <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
        {/* Site Header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content */}
        <div className="z-0 px-4 sm:px-6 lg:px-8">
          <Outlet />
        </div>

        {/* Footer */}
        <footer className="py-5 text-center">
          {year} © Designed by{" "}
          <a
            href="https://ouorange.com/"
            target="_blank"
            className="font-bold text-primary-800"
            rel="noreferrer"
          >
            OUOrange
          </a>
        </footer>
      </div>
    </section>
  )
}

export default Layout
