import BreadCrumb from "components/BreadCrumb"
import Typography from "@mui/material/Typography"
import { Link, useNavigate, useParams } from "react-router-dom"
import DeviceForm from "components/device/DeviceForm"
import { useMutation } from "react-query"
import { putSensor } from "features/utils"
import { useState, useEffect } from "react"
import { toast } from "react-toastify"
import Spinner from "components/Spinner"
import useDeviceData from "hooks/useDeviceData"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import Tabs from "@mui/material/Tabs"
import TabPanel from "@mui/lab/TabPanel"
import { useQuery } from "utils/Query"
import ThresholdForm from "./Form/ThresholdForm"

function EditDevice() {
  const queryParams = useQuery()
  const [value, setValue] = useState("1")
  const [vendorValue, setVendorValue] = useState("")
  const params = useParams()
  const navigate = useNavigate()

  const areaId = params.id
  const { deviceId } = params
  const preLink = `/setting/area/${areaId}/edit/`

  /* Update device data */
  const { mutate } = useMutation(
    (v) => {
      putSensor(v.data, v.id)
    },
    {
      onSuccess: () => {
        navigate(`/setting/area/${areaId}/edit?target=2`)
        toast.success("修改成功")
      },
    }
  )
  const handleOnSubmit = (data) => {
    mutate({ data, id: deviceId })
  }

  const handleChange = (event, newValue) => { // Change TabList
    setValue(newValue)
  }

  /* Get default values of device by id */
  const [defaultDeviceData, setDefaultDeviceData] = useState(null)
  const { deviceData, isError, isLoading, isFetching, error } = useDeviceData(deviceId)
  useEffect(() => {
    if (!deviceData) {
      return
    }
    setDefaultDeviceData({
      name: deviceData?.name,
      vendor: deviceData?.vendor || null,
      description: deviceData?.description,
      data: deviceData?.data,
    })

    setVendorValue(deviceData?.vendor)
  }, [deviceData, isFetching])

  useEffect(() => {
    if (queryParams.get('target') === '1') {
      setValue('1')
    }

    if (queryParams.get('target') === '2') {
      setValue('2')
    }
  }, [queryParams])

  /* Status */
  if (isError) {
    toast.error(error.message)
  }

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      系統設定
    </Typography>,
    <Typography key="2" color="text.primary">
      <Link to="/setting/area">設定場域</Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link to={preLink}>修改場域</Link>
    </Typography>,
    <Typography key="4" color="text.primary">
      <Link to={`${preLink}?target=2`}>感測器</Link>
    </Typography>,
    <Typography key="5" color="text.secondary">
      修改感測器
    </Typography>,
  ]

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <h1 className="my-3 text-2xl font-bold">修改感測器</h1>
      {/* BreadCrumb */}
      <BreadCrumb data={breadcrumbs} />

      <TabContext value={value}>
        <Tabs
          sx={{ borderBottom: 1, borderColor: "divider" }}
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
        >
          <Tab label="Info" value="1" />
          <Tab label="Threshold" value="2" />
        </Tabs>
        <TabPanel value="1" className="w-1/2 mx-auto">
          <DeviceForm defaultValues={defaultDeviceData} handleFormSubmit={handleOnSubmit} />
        </TabPanel>
        <TabPanel value="2">
          {/* Threshold */}
          <ThresholdForm vendorValue={vendorValue} />
        </TabPanel>
      </TabContext>
    </>
  )
}

export default EditDevice
