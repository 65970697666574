import { nanoid } from "@reduxjs/toolkit"
import { getChart } from "features/utils"
import { getDevices } from "features/utils/device"
import { useCallback, useEffect, useState } from "react"
import { useQuery } from "react-query"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, FormControlLabel } from "@mui/material"
import EachChart from "../Setting/Area/Chart/EachChart"

function Chart({ vendorName, vendorAlias, searchAreas }) {
  const [vendorDevices, setVendorDevices] = useState([])
  const [allChecked, setAllChecked] = useState(false)

  const fetchVendorDevices = useCallback(() => {

    const params = {
      vendor: vendorName,
      area_ids: searchAreas
    }

    getDevices(params).then(resp => {
      const devices = resp.data.map(v => ({
        checked: false,
        data: v
      }))
      setVendorDevices(devices)
    })
  }, [])

  const handleClick = (index) => {
    setVendorDevices((prevDevices) => {
      const newDevices = [...prevDevices]
      newDevices[index] = {
        ...newDevices[index],
        checked: !newDevices[index].checked,
      }
      const allSelected = newDevices.every((d) => d.checked)
      setAllChecked(allSelected)
      return newDevices
    })
  }

  const handleAllClick = () => {
    setAllChecked(!allChecked)
    setVendorDevices((prevDevices) => {
      const newDevices = prevDevices.map((device) => ({
        ...device,
        checked: !allChecked,
      }))
      return newDevices
    })
  }

  useEffect(() => {
    fetchVendorDevices()
  }, [])

  return (
    <div>
      <h2 className="pt-2 pb-2 text-lg font-bold">{vendorAlias}</h2>
      <div>
        {vendorDevices.length !== 0 && (
          <FormControlLabel
            control={<Checkbox id="selectAll" onClick={() => handleAllClick()} />}
            label="全部選取"
            checked={allChecked}
          />
        )}
      </div>
      {vendorDevices?.map((v, index) => (
        <FormControlLabel
          key={nanoid()}
          control={<Checkbox onClick={() => handleClick(index)} />}
          label={v.data.name}
          checked={v.checked}
        />
      ))}
      {vendorDevices?.map((v, index) => (
        <div key={nanoid()}>
          {v.checked && (
            <Area
              key={nanoid()}
              deviceId={v.data.id}
              deviceName={v.data.name}
              areaId={v.data.area_id}
            />
          )}
        </div>
      ))}
    </div>
  )
}

function Area({ deviceId, areaId, deviceName }) {
  const [areaChart, setAreaChart] = useState([])

  const fetchChartData = useCallback(() => {
    getChart(deviceId, true).then(resp => {
      setAreaChart(resp?.data?.metrics)
    })
  }, [])


  useEffect(() => {
    fetchChartData()
  }, [])

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          {deviceName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {areaChart &&
          areaChart?.map((group) => {
            const device = group?.data
            const data = device.map((i) => i)
            return (
              <EachChart
                data={data}
                group={group}
                key={nanoid()}
                deviceId={deviceId}
                areaId={areaId}
              />
            )
          })}
      </AccordionDetails>
    </Accordion>
  )
}

export default Chart;