import { TextField, FormControlLabel, Switch, Select, MenuItem, FormControl, InputLabel } from "@mui/material"
import { Controller } from "react-hook-form"

const measurements = [
    'SMARTAGRI_SITE_1',
    'SMARTAGRI_SITE_2',
    'SMARTAGRI_SITE_3',
    'SMARTAGRI_SITE_4',
    'SMARTAGRI_SITE_5',
    'SMARTAGRI_SITE_6'
]

function SmartAGRISite({ control }) {

    return (
        <>
            <div className="mt-8">
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">區域</InputLabel>
                    <Controller
                        className="w-full"
                        render={({ field: { value, onChange } }) => (
                            <Select
                                labelId="demo-simple-select-label"
                                label="區域"
                                value={value}
                                onChange={onChange}
                            >
                                {measurements?.map((x) => (
                                    <MenuItem key={x} value={x}>
                                        {x}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        name="data.measurement"
                        control={control}
                        defaultValue=""
                    />
                </FormControl>
            </div>
            <div className="mt-8">
                <Controller
                    render={({ field }) => <TextField {...field} fullWidth variant="outlined" label="裝置ID" />}
                    name="data.deviceId"
                    control={control}
                    defaultValue=""
                />
            </div>
        </>
    )
}

export default SmartAGRISite
