import { useState, useEffect } from "react"
import { Box, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material"
import useVendorData from "hooks/useVendorData"
import { Controller, useForm } from "react-hook-form"
import CustomDialog from "components/dialog/CustomDialog"
import WeatherAngel from "components/vendors/WeatherAngel"
import Meterindco from "components/vendors/Meterindco"
import { toast } from "react-toastify"
import SmartAGRISite from "components/vendors/SmartAGRISite"
import CentralWeather from "components/vendors/CentralWeather"
import Quadlink from "components/vendors/Quadlink"

function DeviceForm(props) {
  const { defaultValues, handleFormSubmit } = props
  const [vendors] = useVendorData()
  const [open, setOpen] = useState(false)
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  // set form values
  useEffect(() => {
    if (!defaultValues) {
      return
    }
    setValue("name", defaultValues?.name)
    setValue("vendor", defaultValues?.vendor)
    defaultValues?.description && setValue("description", defaultValues.description)
    setValue("data", defaultValues?.data)
  }, [defaultValues, setValue])

  // vendor components
  const [vendorForm, setVendorForm] = useState(null)
  const vendor = watch("vendor")
  useEffect(() => {
    if (vendor === undefined) {
      return
    }
    const vendorFormComponents = {
      WEATHERANGEL: <WeatherAngel control={control} />,
      METERINDCO: <Meterindco control={control} />,
      WEATHERANGEL_IMAGE: <WeatherAngel control={control} />,
      SMARTAGRI_SITE: <SmartAGRISite control={control} />,
      CENTRAL: <CentralWeather control={control} />,
      QUADLINK: <Quadlink control={control} />
    }
    setVendorForm(vendorFormComponents[vendor])
  }, [vendor, control])

  // form submit
  const onSubmit = (data, e) => {
    if (!open) {
      setOpen(true)
      return
    }
    handleFormSubmit(data)
  }
  const onError = (err, e) => {
    toast.error(err.message)
  }

  /* close dialog window */
  const handleCloseDialog = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ maxWidth: 400 }} className="mt-10 mx-auto">
      <CustomDialog
        handleClose={handleCloseDialog}
        method={handleSubmit(onSubmit, onError)}
        open={open}
        content="修改"
      />
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">裝置</InputLabel>
          <Controller
            render={({ field: { value, onChange } }) => (
              <Select
                labelId="demo-simple-select-label"
                label="vendor"
                value={value}
                onChange={onChange}
              >
                {vendors?.map((x) => (
                  <MenuItem key={x.name} value={x.name}>
                    {x.label}
                  </MenuItem>
                ))}
              </Select>
            )}
            name="vendor"
            control={control}
            defaultValue=""
          />
        </FormControl>
        <div className="mt-8">
          <Controller
            render={({ field }) => (
              <TextField {...field} fullWidth variant="outlined" label="名稱" />
            )}
            name="name"
            control={control}
            defaultValue=""
          />
        </div>
        <div className="mt-8">
          <Controller
            render={({ field }) => (
              <TextField {...field} fullWidth variant="outlined" label="描述" rows={6} multiline />
            )}
            name="description"
            control={control}
            defaultValue=""
          />
        </div>
        {vendorForm}
        <button
          type="submit"
          className="w-full px-4 py-2 tracking-wide 
                    text-white transition-colors duration-200 transform bg-primary-600 rounded-md hover:bg-primary-300 focus:outline-none focus:bg-primary-300 mt-12"
        >
          送出
        </button>
      </form>
    </Box>
  )
}

export default DeviceForm
