import { nanoid } from "@reduxjs/toolkit"
import Spinner from "components/Spinner"
import { getChart } from "features/utils"
import { useState, useEffect } from "react"
import { useQuery } from "react-query"
import { useParams, Link } from "react-router-dom"
import { toast } from "react-toastify"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import Tabs from "@mui/material/Tabs"
import TabPanel from "@mui/lab/TabPanel"

import BreadCrumb from "components/BreadCrumb"
import Typography from "@mui/material/Typography"
import EachChart from "./Chart/EachChart"
import ChartTable from "./Chart/ChartTable"

function ViewChart() {
  const params = useParams()
  const { deviceId } = params
  const areaId = params.id
  const preLink = `/setting/area/${areaId}/edit`

  /* Tab Function */
  const [value, setValue] = useState("1")
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  /* Get Chart */
  const { isError, isLoading, error, data } = useQuery("getChart", () => getChart(deviceId), {
    cacheTime: 0,
  })

  const [areaChart, setAreaChart] = useState(data)
  useEffect(() => {
    setAreaChart(data?.data?.metrics)
  }, [isLoading])

  /* Status feedback */
  if (isLoading) {
    return <Spinner />
  }

  if (isError) {
    toast.error(error.message)
  }

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      系統設定
    </Typography>,
    <Typography key="2" color="text.primary">
      <Link to="/setting/area">設定場域</Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link to={preLink}>修改場域</Link>
    </Typography>,
    <Typography key="4" color="text.primary">
      <Link to={`${preLink}?target=2`}>感測器</Link>
    </Typography>,
    <Typography key="5" color="text.secondary">
      查看圖表
    </Typography>,
  ]

  return (
    <>
      <h1 className="text-2xl font-bold my-3">查看圖表</h1>

      {/* BreadCrumb */}
      <BreadCrumb data={breadcrumbs} />
      {/* Tab */}
      <Box sx={{ width: "100%", typography: "body1" }} className="my-3">
        <TabContext value={value}>
          <Tabs
            sx={{ borderBottom: 1, borderColor: "divider" }}
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
          >
            <Tab label="Chart" value="1" />
            <Tab label="Table" value="2" />
          </Tabs>
          <TabPanel value="1">
            {areaChart?.map((group) => {
              const device = group?.data
              const data = device.map((i) => i)
              return <EachChart data={data} group={group} key={nanoid()} />
            })}
          </TabPanel>
          <TabPanel value="2">
            <ChartTable />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  )
}

export default ViewChart
