import UserMenu from "./header/UserMenu"

function Header({ sidebarOpen, setSidebarOpen }) {
  const envValue = `${process.env.REACT_APP_TITLE}`

  const colorCtl = {
    header_bgc: envValue === "智慧養殖跨場域資料庫分析平台" ? "bg-primary-100" : "bg-green-100",
  }

  return (
    <header className={`sticky top-0 border-b border-slate-200 z-30 ${colorCtl.header_bgc}`}>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex items-center">
            {/* Hamburger button */}
            <button
              className="text-slate-500 hover:text-slate-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={() => {
                setSidebarOpen(!sidebarOpen)
              }}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
            <h1 className="text-sm tracking-wide md:text-xl">{process.env.REACT_APP_TITLE}</h1>
          </div>

          {/* Header: Right side */}
          <div className="flex items-center">
            {/*  Divider */}
            <hr className="w-px h-6 mx-3 bg-slate-200" />
            <UserMenu />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
