import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  role: "",
}

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    addRole: (state, action) => {
      state.role = action.payload
    },
    initialRole: (state, action) => {
      state.role = ""
    },
  },
})

export const { addRole, initialRole } = roleSlice.actions
export default roleSlice.reducer
