import Spinner from "components/Spinner";
import { getVendorMetrics } from "features/utils"
import { getThreshold } from "features/utils/threshold";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ThresholdField from "./components/ThresholdField";

export default function ThresholdForm({ vendorValue }) {
    const params = useParams()
    const [isFetchVendorMetrics, setIsFetchVendorMetrics] = useState(false)
    const [metricsData, setMetricsData] = useState([])
    const [thresholdData, setThresholdData] = useState([])

    useEffect(() => {
        if (vendorValue === '') {
            return undefined
        }

        setIsFetchVendorMetrics(true)
        getVendorMetrics(vendorValue).then(resp => {
            setMetricsData(resp)
        }).catch(err => {
            toast.error('無法抓取感測器欄位')
        }).finally(() => {
            setIsFetchVendorMetrics(false)
        })
    }, [vendorValue])

    useEffect(() => {
        if (params.deviceId === undefined && params.deviceId === null) {
            return null
        }
        getThreshold({ device_id: params.deviceId })
            .then(resp => { setThresholdData(resp) })
            .catch(err => toast.error('無法抓取警示閥值'))
    }, [params])

    return isFetchVendorMetrics ? <Spinner /> : (
        <>
            {
                metricsData.map(group =>
                    <div key={group.group}>
                        <h1 className="text-lg font-bold">{group.group} 警戒參數設定：</h1>
                        <ThresholdField group={group} thresholds={thresholdData} deviceId={params.deviceId} />
                    </div>
                )
            }
        </>
    )
}

