import { newHttpInstance } from "./common/http"

const API_URL = "/api/v1/"

export async function getThreshold(params = {}) {
    try {
        const searchParams = new URLSearchParams(params);
        let queryParams = '';
        if (searchParams !== '') {
            queryParams = `?${searchParams}`
        }
        const instance = newHttpInstance("GET", `${API_URL}thresholds${queryParams}`)
        const res = await instance.request()
        return res.data
    } catch (error) {
        throw new Error(error)
    }
}

export async function createThreshold(data) {
    try {
        const instance = newHttpInstance("POST", `${API_URL}thresholds`)
        const res = await instance.request({
            data
        })
        return res.data
    } catch (error) {
        throw new Error(error)
    }
}