import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area } from "recharts"
import Box from "@mui/material/Box"


const normalColor = '#9696FC'; const waringColor = '#FFE599'; const errorColor = '#F44336'

export function LineChart({ chart }) {
    const newChart = JSON.parse(JSON.stringify(chart))
    const newItem = newChart?.values?.map((i) => {
        const time = new Date(i.time)
        const h = time.getHours().toString().padStart(2, "0")
        const m = time.getSeconds().toString().padStart(2, "0")
        i.time = `${h}:${m}`
        return i
    })

    let color = normalColor
    if (newChart.exceeded_type === 1) {
        color = waringColor
    }else if (newChart?.exceeded_type === 2) {
        color = errorColor
    }

    const uvName = `colorUv-${newChart?.field}`
    
    return (
        <Box>
            <div className="flex justify-between mx-8 mt-4">
                <div>
                    <p className="px-3 py-1 text-lg font-bold bg-gray-200 rounded-full">
                        {chart.label}
                    </p>
                </div>
                <div className="flex flex-col items-end">
                    <p>{chart.unit}</p>
                    <p>{new Date(chart.last_time).toLocaleString("zh-tw")}</p>
                </div>
            </div>
            <AreaChart
                width={400}
                height={150}
                data={newItem}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                padding={{ top: 20 }}
            >
                <defs>
                    <linearGradient id={uvName} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                        <stop offset="95%" stopColor={color} stopOpacity={0} />
                    </linearGradient>
                </defs>
                <XAxis dataKey="time" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area
                    type="monotone"
                    dataKey="value"
                    stroke={color}
                    fillOpacity={1}
                    fill={`url(#${uvName})`}
                />
            </AreaChart>
        </Box>
    )
}