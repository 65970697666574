import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { nanoid } from 'nanoid';
import { ThresholdFieldForm } from './ThresholdFieldForm';

function ThresholdField({ group, thresholds, deviceId }) {

    return (
        <div className="mt-4">
            {
                group.data.map(data => <ThresholdFieldForm key={nanoid()} data={data} thresholds={thresholds} deviceId={deviceId} />)
            }
        </div>
    )
}

export default ThresholdField