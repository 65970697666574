import { newHttpInstance } from "features/utils/common/http"

const API_URL = "/api/v1/"

// Get JWT Time
export async function getJwtTime() {
    try {
        const instance = newHttpInstance("GET", `${API_URL}jwt-time`)
        const res = await instance.request()
        return res
    } catch (error) {
        throw new Error(error)
    }
}

// PUT JWT Time
export async function putJwtTime(data = {}) {
    try {
        const instance = newHttpInstance("PUT", `${API_URL}ch-jwt`)
        const res = await instance.request({
            data,
        })
        return res
    } catch (error) {
        throw new Error(error)
    }
}
