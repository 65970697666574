import { Link, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { useState } from "react"
import { useMutation } from "react-query"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import Tabs from "@mui/material/Tabs"
import TabPanel from "@mui/lab/TabPanel"
import { toast } from "react-toastify"
import { postAdmin } from "features/utils"
import Spinner from "components/Spinner"
import CustomDialog from "components/dialog/CustomDialog"
import BreadCrumb from "components/BreadCrumb"
import ManagerForm from "./Form/ManagerForm"

function CreateManager() {
  const { role } = useSelector((state) => state.role)

  const navigate = useNavigate()
  const [value, setValue] = useState("1")
  const [getId, setGetId] = useState(null)
  const [managerData, setManagerData] = useState({
    name: "",
    email: "",
    username: "",
    password: "",
    confirmationPassword: "",
  })

  const { name, email, username, password, confirmationPassword } = managerData
  const { isLoading, isError, error, mutate } = useMutation(postAdmin, {
    onSuccess: (data) => {
      setGetId(data?.data.id)
      toast.success("新增成功")
    },
    onError: (error) => {
      handleClose()
      toast.error(error.response.data.msg)
    },
  })

  // Dialog
  const [open, setOpen] = useState(false)
  const handleClickOpen = (e) => {
    e.preventDefault()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // Change TabList
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const onChange = (e) => {
    setManagerData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onAddSubmit = (e) => {
    e.preventDefault()
    const submitData = {
      name,
      email,
      username,
      password,
      password_confirmation: confirmationPassword,
      role,
    }
    mutate(submitData)
  }

  if (isLoading) {
    return <Spinner />
  }

  if (getId !== null) {
    navigate(`/setting/manager`)
    setGetId(null)
  }

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      系統設定
    </Typography>,
    <Typography key="2" color="text.primary">
      <Link to="/setting/manager">登入帳號管理</Link>
    </Typography>,
    <Typography key="3" color="text.secondary">
      新增管理員
    </Typography>,
  ]

  return (
    <>
      <CustomDialog handleClose={handleClose} method={onAddSubmit} open={open} content="新增" />
      <h1 className="text-2xl font-bold my-3">新增權限群組</h1>

      {/* BreadCrumb */}
      <BreadCrumb data={breadcrumbs} />

      {/* Tab */}
      <Box sx={{ width: "100%", typography: "body1" }} className="my-3">
        <TabContext value={value}>
          <Tabs
            sx={{ borderBottom: 1, borderColor: "divider" }}
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
          >
            <Tab label="新增管理員" value="1" />
          </Tabs>
          {/* Add Area */}
          <TabPanel value="1" className="w-1/2 mx-auto">
            <ManagerForm handleClickOpen={handleClickOpen} onChange={onChange} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  )
}

export default CreateManager
