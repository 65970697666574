import BreadCrumb from "components/BreadCrumb"
import Typography from "@mui/material/Typography"
import { useForm, Controller } from "react-hook-form"
import { TextField } from "@mui/material"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import SmallButton from "components/button/predict/SmallButton"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { createPredictModel } from "features/utils/predict-model"
import { toast } from "react-toastify"
import { PredictForm } from "./PredictForm"
import { TrainForm } from "./TrainForm"

function CreateTrain() {

  const navigate = useNavigate()

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      AI 預測模型
    </Typography>,
    <Typography key="2" color="text.primary">
      <Link to="/predict/train-model">訓練模式</Link>
    </Typography>,
    <Typography key="2" color="text.secondary">
      新增訓練模式
    </Typography>,
  ]

  const defaultValues = {
    devices: [],
    features: [],
    targets: [],
  }

  const onSubmit = (data) => {
    data.mode = 1
    createPredictModel(data).then(resp => {
      toast.success('新增成功')
      navigate('/predict/train-model')
    }).catch(e => {
      toast.error(e.error)
    }).finally(() => {

    })
  }

  return (
    <TrainForm handleOnSubmit={onSubmit} breadcrumbs={breadcrumbs} defaultValues={defaultValues} />
  )
}

export default CreateTrain
