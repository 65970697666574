import BreadCrumb from "components/BreadCrumb"
import { Link, useNavigate, useParams } from "react-router-dom"
import Typography from "@mui/material/Typography"
import { useMutation } from "react-query"
import { postDevice } from "features/utils"
import { toast } from "react-toastify"
import DeviceForm from "components/device/DeviceForm"

function CreateDevice() {
  const params = useParams()
  const navigate = useNavigate()

  // BreadCrumb get previous id
  const areaId = params.id
  const preLink = `/setting/area/${areaId}/edit`

  // Post Device Data
  const { mutate } = useMutation(postDevice, {
    onSuccess: () => {
      navigate(`${preLink}?target=2`)
      toast.success("新增成功")
    },
  })
  const handleOnSubmit = (data) => {
    mutate({ ...data, area_id: parseInt(areaId, 10) || 0 })
  }

  // default values
  const defaultDeviceData = {
    vendor: "WEATHERANGEL",
    name: "",
    description: "",
    data: {
      data_type: "",
      station_id: "",
    },
  }

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      系統設定
    </Typography>,
    <Typography key="2" color="text.primary">
      <Link to="/setting/area">設定場域</Link>
    </Typography>,
    <Typography key="3" color="text.primary">
      <Link to={preLink}>修改場域</Link>
    </Typography>,
    <Typography key="4" color="text.primary">
      <Link to={`${preLink}?target=2`}>感測器</Link>
    </Typography>,
    <Typography key="5" color="text.secondary">
      新增感測器
    </Typography>,
  ]

  return (
    <>
      <h1 className="text-2xl font-bold my-3">修改感測器</h1>
      {/* BreadCrumb */}
      <BreadCrumb data={breadcrumbs} />
      <DeviceForm defaultValues={defaultDeviceData} handleFormSubmit={handleOnSubmit} />
    </>
  )
}

export default CreateDevice
