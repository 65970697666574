import Typography from "@mui/material/Typography"
import BreadCrumb from "components/BreadCrumb"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import Tabs from "@mui/material/Tabs"
import TabPanel from "@mui/lab/TabPanel"
import { useCallback, useEffect, useState } from "react"
import { getCategoriesVendor } from "features/utils/vendor-setting"
import Table from "pages/Realtime/Table"
import Chart from "pages/Realtime/Chart"
import { nanoid } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"

const breadcrumbs = [
  <Typography key="1" color="text.primary">
    即時資料顯示
  </Typography>,
]

function Realtime() {

  const { ownAreaList } = useSelector((state) => state.ownPermission)

  const [value, setValue] = useState("1")
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [waterQualityData, setWaterQualityData] = useState([])
  const [meteorologicalData, setMeteorologicalData] = useState([])

  // 水質
  const fetchWaterQualityData = useCallback(() => {
    getCategoriesVendor(1).then(resp => {
      setWaterQualityData(resp)
    })
  })

  // 氣象
  const fetchMeteorologicalData = useCallback(() => {
    getCategoriesVendor(2).then(resp => {
      setMeteorologicalData(resp)
    })
  })

  useEffect(() => {
    fetchWaterQualityData()
    fetchMeteorologicalData()
  }, [])

  return (
    <>
      <h1 className="my-3 text-2xl font-bold">即時資料顯示</h1>
      <BreadCrumb data={breadcrumbs} />
      <Box sx={{ width: "100%", typography: "body1" }} className="my-3">
        <TabContext value={value}>
          <Tabs
            sx={{ borderBottom: 1, borderColor: "divider" }}
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
          >
            <Tab label="Chart" value="1" />
            <Tab label="Table" value="2" />
          </Tabs>
          <TabPanel value="1">
            <h1 className="my-3 text-2xl font-bold">水質</h1>
            <hr />
            {waterQualityData.map((v) => (
              <Chart key={nanoid()} vendorName={v.name} vendorAlias={v.alias} searchAreas={ownAreaList}/>
            ))}
            <h1 className="my-3 text-2xl font-bold">氣象</h1>
            <hr />
            {meteorologicalData.map((v) => (
              <Chart key={nanoid()} vendorName={v.name} vendorAlias={v.alias} searchAreas={ownAreaList}/>
            ))}
          </TabPanel>
          <TabPanel value="2">
            <Table searchAreas={ownAreaList} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  )
}

export default Realtime