import BreadCrumb from "components/BreadCrumb"
import Typography from "@mui/material/Typography"
import SmallButton from "components/button/predict/SmallButton"
import MUIDataTable from "mui-datatables"
import { useNavigate } from "react-router-dom"
import "./style/style.scss"
import { useCallback, useState, useEffect } from "react"
import { deletePredictModel, getPredictions } from "features/utils/predict-model"
import { toast } from "react-toastify"
import CustomDialog from "components/dialog/CustomDialog"
import moment from 'moment'

function Train() {
  const navigate = useNavigate()
  const [predictions, setPredictions] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteRows, setDeleteRows] = useState([]);

  const navigateCreatePage = () => {
    navigate("/predict/train-model/create")
  }

  const navigateEditPage = (id) => {
    navigate(`/predict/train-model/${id}/edit`)
  }

  const navigateChartPage = (id) => {
    navigate(`/predict/train-model/${id}/chart`)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
  }

  const handleFetchPredictions = useCallback(() => {
    getPredictions({
      mode: 1
    }).then(resp => setPredictions(resp.data))
      .catch(e => toast.error(e.message))
  }, [])

  const handleDeletePrediction = (indexes) => {
    if (!deleteDialogOpen) {
      setDeleteDialogOpen(true)
      setDeleteRows(indexes)
      return
    }

    const data = predictions.filter((v, index) => deleteRows.indexOf(index) !== -1)
    const ids = data.map(v => v.id)
    deletePredictModel(ids)
      .then(resp => toast.success('刪除成功'))
      .catch(e => toast.error(e.message))
      .finally(() => {
        setDeleteRows([])
        setDeleteDialogOpen(false)
        handleFetchPredictions()
      })
  }

  useEffect(() => {
    handleFetchPredictions();
  }, [])

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      AI 預測模型
    </Typography>,
    <Typography key="2" color="text.secondary">
      訓練模式
    </Typography>,
  ]

  const columns = [
    {
      name: "name",
      label: "訓練模型名稱",
      options: {
        filter: false,
        sort: false
      },
    },
    {
      name: "begin_time",
      label: "日期",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const val = predictions[dataIndex];
          return `${val.begin_time} - ${val.end_time}`;
        }
      },
    },
    {
      name: "model",
      label: "機器學習模型",
      options: {
        filter: false,
        sort: false
      },
    },
    {
      name: "created_at",
      label: "新增日期",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (moment(value).format('YYYY-MM-DD hh:mm:ss'))
      },
    },
    {
      name: "id",
      label: "操作",
      options: {
        filter: false,
        sort: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex) => {
          const val = predictions[dataIndex];
          return (
            <div className="flex justify-center gap-4 align-center e">
              <button
                onClick={() => {
                  navigateEditPage(val.id)
                }}
                className="w-20 px-3 py-1 font-bold text-white bg-blue-400 rounded hover:bg-blue-300 borde align-center"
              >
                編輯
              </button>
              {/* <button
                onClick={() => {
                  navigateChartPage(val.id)
                }}
                className="w-20 px-3 py-1 font-bold text-white bg-yellow-400 rounded hover:bg-yellow-300 borde align-center"
              >
                圖表
              </button> */}
              <button
                onClick={() => {
                  handleDeletePrediction([dataIndex])
                }}
                className="w-20 px-3 py-1 font-bold text-white bg-red-400 rounded hover:bg-red-300 borde align-center"
              >
                刪除
              </button>
            </div>
          )
        }
      },
    }
  ];

  const options = {
    responsive: "standard",
    textLabels: {
      body: {
        noMatch: "尚未有資料",
      },
    },
    print: false,
    download: false,
    filter: false,
    sort: false,
    rowsSelected: deleteRows,
    onRowsDelete: (rowsDeleted, newTableData) => {
      const indexes = rowsDeleted.data.map((x) => x.dataIndex)
      handleDeletePrediction(indexes)
      return false
    },
  }

  return (
    <>
      <h1 className="my-3 text-2xl font-bold">訓練模式</h1>
      <BreadCrumb data={breadcrumbs} />
      <div className="flex justify-end mb-4">
        <button
          onClick={navigateCreatePage}
          className="w-20 px-3 py-1 font-bold text-white bg-green-400 rounded hover:bg-green-300 borde align-center"
        >
          新增
        </button>
      </div>
      <div className="train-dataTable">
        <MUIDataTable data={predictions} columns={columns} options={options} />
      </div>
      <CustomDialog open={deleteDialogOpen} method={handleDeletePrediction} handleClose={handleCloseDeleteDialog} content="刪除" />
    </>
  )
}

export default Train
