import TextField from "@mui/material/TextField"

function ManagerInfoForm({ managerInfo = "", onChange, handleClickOpen }) {
  return (
    <form className="mt-8" onSubmit={handleClickOpen}>
      <div>
        <TextField
          className="w-full"
          label="管理員名稱"
          variant="outlined"
          onChange={onChange}
          name="name"
          required
          defaultValue={managerInfo?.name}
        />
      </div>
      <div className="mt-4">
        <TextField
          className="w-full"
          label="管理員信箱"
          variant="outlined"
          onChange={onChange}
          name="email"
          required
          defaultValue={managerInfo?.email}
        />
      </div>
      <button
        type="submit"
        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary-600 rounded-md hover:bg-primary-300 focus:outline-none focus:bg-primary-300 mt-12"
      >
        送出
      </button>
    </form>
  )
}

export default ManagerInfoForm
