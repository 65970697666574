import { FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextareaAutosize } from "@mui/material"
import BreadCrumb from "components/BreadCrumb"
import { getAreas } from "features/utils/area";
import { getDevices } from "features/utils/device";
import { useEffect, useState, useCallback } from "react";
import { Controller, useForm } from "react-hook-form"
import DateAndTimePicker from "./components/DateAndTimePicker"

export function EventForm({
    onSubmit, defaultValues
}) {
    const { handleSubmit, control, watch, reset, getValues } = useForm({ defaultValues })
    const [areas, setAreas] = useState([])
    const [devices, setDevices] = useState([])
    const [deviceDisplay, setDeviceDisplay] = useState(false)
    const [selectAreaId, setSelectAreaId] = useState(0)

    const fetchAreas = useCallback(() => {
        getAreas().then(resp => setAreas(resp.data))
    }, [])

    const handleFetchDevice = useCallback(() => {
        getDevices({
            area_id: selectAreaId,
        }).then(resp => {
            setDevices(resp.data)
            setDeviceDisplay(true)
        })
    }, [])

    const handleOnChangeArea = e => {
        const v = e.target.value
        setSelectAreaId(v)
    }

    useEffect(() => {
        fetchAreas()
    }, [])

    useEffect(() => {
        if (selectAreaId === 0) {
            return
        }
        handleFetchDevice()
    }, [selectAreaId])

    useEffect(() => {
        reset(defaultValues)
    }, [defaultValues])

    return (
        <form className="w-2/3 mx-auto mt-9" onSubmit={handleSubmit(onSubmit)} >
            <Controller
                name="immediate"
                control={control}
                render={({ field }) => (
                    <RadioGroup row {...field}>
                        <div className="flex pb-6">
                            <div className="mr-6 my-auto font-bold">即時訊息</div>
                            <FormControlLabel value={1} control={<Radio />} label="是" />
                            <FormControlLabel value={0} control={<Radio />} label="否" />
                        </div>
                    </RadioGroup>
                )}
            />

            <Controller
                control={control}
                name="time"
                render={({ field: { value, onChange } }) => (
                    <div className="flex pb-6">
                        <h1 className="mr-6 font-bold">訊息時間</h1>
                        <DateAndTimePicker
                            currentDate={value}
                            handleChange={onChange}
                        />
                    </div>
                )}
            />

            <Controller
                name="message"
                control={control}
                render={({ field: { value: textAreaValue, onChange: textAreaOnchange } }) =>
                    <div className="flex pb-6">
                        <h1 className="mr-6 font-bold">警示訊息</h1>
                        <TextareaAutosize
                            minRows={3}
                            style={{ width: 500 }}
                            value={textAreaValue}
                            onChange={textAreaOnchange}
                        />
                    </div>
                }
            />

            <div className="flex mb-6">
                <div className="flex">
                    <h1 className="mr-4 my-auto font-bold">場域選擇</h1>
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                        <Select
                            value={selectAreaId}
                            onChange={handleOnChangeArea}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            {
                                areas?.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                )
                                )
                            }
                        </Select>
                    </FormControl>
                </div>
                {deviceDisplay && <Controller
                    control={control}
                    name="device_id"
                    render={({ field: { value, onChange } }) =>
                        <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <Select
                                value={value}
                                onChange={onChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {
                                    devices?.map(item => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    }
                />}

            </div>

            <Controller
                control={control}
                name="state"
                render={({ field: { value, onChange } }) =>
                    <div className="flex">
                        <h1 className="mr-4 my-auto font-bold">訊息類別</h1>
                        <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <Select
                                value={value}
                                onChange={onChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value={0}>其他</MenuItem>
                                <MenuItem value={1}>警戒</MenuItem>
                                <MenuItem value={2}>危險</MenuItem>

                            </Select>
                        </FormControl>
                    </div>
                }
            />
            <button
                type="submit"
                className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary-600 rounded-md hover:bg-primary-300 focus:outline-none focus:bg-primary-300 mt-12"
            >
                送出
            </button>
        </form>
    )
}