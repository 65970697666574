/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useState } from "react"

import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

function Meterindco() {
  const [value, setValue] = useState(1)

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const renderVideo = () => {
    if (value === 1) {
      return <video src="/stream-ch1" id="video" autoPlay="autoPlay" width={900} />
    }
    if (value === 2) {
      return <video src="/stream-ch2" id="video" autoPlay="autoPlay" width={900} />
    }
    if (value === 3) {
      return <video src="/stream-ch3" id="video" autoPlay="autoPlay" width={900} />
    }
  }

  return (
    <>
      <h1 className="my-3 text-2xl font-bold">固定式監控影像</h1>

      <Box sx={{ maxWidth: 200 }} className="mb-4">
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={handleChange}
          >
            <MenuItem value={1}>台南七股</MenuItem>
            <MenuItem value={2}>台南七股</MenuItem>
            <MenuItem value={3}>嘉義義竹寶蝦</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {renderVideo()}
    </>
  )
}

export default Meterindco
