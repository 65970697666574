import Typography from "@mui/material/Typography"
import { SearchForm } from "components/area/SearchForm"
import BreadCrumb from "components/BreadCrumb"
import { getChartTable } from "features/utils"
import { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { SwitchAreaButton } from "./SwitchAreaButton"
import { ColumeLineChart } from "./ColumeLineChart"
import { HistoryChart } from "./HistoryChart"


const breadcrumbs = [
  <Typography key="1" color="text.primary">
    歷史資料總覽
  </Typography>,
  <Typography key="2" color="text.secondary">
    一般查詢
  </Typography>,
]

function General() {
  const { ownAreaList } = useSelector((state) => state.ownPermission)
  const [chartData, setChartData] = useState(null)
  const { handleSubmit, control, register } = useForm({
    device_id: 0
  })

  const fetchChartTable = useCallback((deviceId, beginTime, endTime) => {
    getChartTable(deviceId, beginTime, endTime).then(resp => {
      setChartData(resp.data)
    })
  }, [])

  const onSubmit = (data) => {
    // eslint-disable-next-line camelcase
    const { device_id, end_time, start_time } = data
    // eslint-disable-next-line camelcase
    if (device_id === 0 || device_id === '0' || device_id === undefined || device_id === null) {
      return
    }
    fetchChartTable(device_id, start_time, end_time)
  }

  return (
    <>
      <h1 className="text-2xl font-bold my-3">一般查詢</h1>
      <BreadCrumb data={breadcrumbs} />
      <SearchForm
        areaIds={ownAreaList}
        register={register}
        control={control}
        handleSubmit={handleSubmit(onSubmit)}
      />
      {chartData !== null && <HistoryChart respData={chartData} />}
    </>
  )
}


export default General
