import { useQuery, useMutation } from "react-query"
import { useSelector } from "react-redux"
import MUIDataTable from "mui-datatables"
import Typography from "@mui/material/Typography"
import Spinner from "components/Spinner"
import { toast } from "react-toastify"
import { getAdmins, deleteAdmin } from "features/utils"
import AddButton from "components/button/AddButton"
import EditButton from "components/button/EditButton"
import { useState } from "react"
import CustomDialog from "components/dialog/CustomDialog"
import { useNavigate } from "react-router-dom"
import BreadCrumb from "components/BreadCrumb"
import moment from "moment"

function Manager() {
  const { ownPermissionList } = useSelector((state) => state.ownPermission)

  const { data, error, isError, isLoading, refetch } = useQuery("admins", getAdmins)

  // state
  const [deleteId, setDeleteId] = useState({ ids: [] })

  // mutation
  const { mutate } = useMutation(() => deleteAdmin(deleteId), {
    onSuccess: () => {
      refetch()
      toast.success("刪除成功")
    },
    onError: (err) => {
      toast.error(err)
    },
  })

  // breadcrumbs
  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      系統設定
    </Typography>,
    <Typography key="2" color="text.secondary">
      登入帳號管理
    </Typography>,
  ]

  // Add Button Navigate
  const navigate = useNavigate()
  const navigateToAddManager = () => {
    navigate("/setting/manager/create")
  }

  // Dialog
  const [open, setOpen] = useState(false)
  const handleClickOpen = (deleteId) => {
    setOpen(true)
    const indexes = deleteId.data.map((x) => x.dataIndex)
    const ids = []
    indexes.forEach((i) => {
      ids.push(records[i][0])
    })
    const result = { ids }
    setDeleteId(result)
  }

  const extraRole = (role) => {
    let roleName = ""
    role.forEach((v) => {
      roleName += v.name
    })
    return roleName
  }

  const records = data?.data?.map((record) => {
    const time = new Date(record?.created_at).toLocaleString("zh-tw")
    return [
      record?.id,
      record?.name,
      record?.description,
      extraRole(record?.role),
      moment(record?.created_at).format("YYYY-MM-DD hh:mm:ss"),
      <div className="flex">
        {ownPermissionList.includes("ADMIN_UPDATE") && (
          <EditButton link={`/setting/manager/${record?.id}/edit`} content="修改" />
        )}
      </div>,
    ]
  })

  const deleteRow = () => {
    mutate(deleteId)
    setOpen(false)
    setDeleteId({ ids: [] })
  }

  const handleClose = () => {
    setOpen(false)
  }

  // DataTable columns title
  const columns = ["ID", "名稱", "描述", "角色", "新增時間", "修改"]

  // DataTable Options
  const options = {
    responsive: "standard",
    filterType: "checkbox",
    print: false,
    download: false,
    selectableRowsOnClick: true,
    onRowsDelete: (rowsDeleted) => {
      handleClickOpen(rowsDeleted)
    },
  }

  if (!ownPermissionList.includes("ADMIN_DELETE")) {
    options.customToolbarSelect = () => {}
  }

  if (isLoading) {
    return <Spinner />
  }

  if (isError) {
    toast.error(error.message)
  }

  return (
    <>
      <CustomDialog open={open} method={deleteRow} handleClose={handleClose} content="刪除" />
      <h1 className="my-3 text-2xl font-bold">登入帳號管理</h1>
      {/* BreadCrumb */}
      <BreadCrumb data={breadcrumbs} />

      {/* Add Button */}
      <div className="flex justify-end">
        {ownPermissionList.includes("ADMIN_CREATE") && (
          <AddButton pageNavigate={navigateToAddManager} text="管理員" />
        )}
      </div>

      {/* Table */}
      <MUIDataTable title="管理員列表" data={records} columns={columns} options={options} />
    </>
  )
}

export default Manager
