import { TextField, FormControlLabel, Switch } from "@mui/material"
import { Controller } from "react-hook-form"

function Meterindco(props) {
  const { control } = props

  return (
    <>
      <div className="mt-8">
        <Controller
          render={({ field }) => <TextField {...field} fullWidth variant="outlined" label="MAC" />}
          name="data.mac"
          control={control}
          defaultValue=""
        />
      </div>
      <div className="mt-8">
        <Controller
          render={({ field }) => <TextField {...field} fullWidth variant="outlined" label="間格" />}
          name="data.interval"
          control={control}
          defaultValue=""
        />
      </div>
      <div className="mt-8">
        <Controller
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              control={
                <Switch
                  checked={value === 1}
                  onChange={(event, val) => onChange(val ? 1 : 0)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="開關"
              className="mt-8"
            />
          )}
          name="data.enable"
          control={control}
          defaultValue="0"
        />
      </div>
    </>
  )
}

export default Meterindco
