import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useQuery } from "react-query"
import { toast } from "react-toastify"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { getRoles } from "features/utils"
import { addRole, initialRole } from "features/role/roleSlice"

function RoleSelect({ selected = "" }) {
  const dispatch = useDispatch()
  const { role } = useSelector((state) => state.role)
  const [roles, setRoles] = useState([])

  const handleChange = (event) => {
    dispatch(addRole(event.target.value))
  }

  const { isFetching, data, error, isError, isLoading, refetch } = useQuery("roles", getRoles)

  if (isError) {
    toast.error(error.message)
  }

  useEffect(() => {
    dispatch(initialRole())
    if (selected) {
      selected.forEach((v) => {
        dispatch(addRole(v.id))
      })
    }
    const rolesData = data?.data
    setRoles(rolesData)
  }, [isFetching, roles])

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">角色</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Role"
        value={role}
        onChange={handleChange}
      >
        <MenuItem value="" disabled>
          <em>請選擇權限角色</em>
        </MenuItem>
        {roles?.map((role) => (
          <MenuItem key={role.id} value={role.id}>
            {role.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default RoleSelect
