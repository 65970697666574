import { newHttpInstance } from "features/utils/common/http"

const API_URL = "/api/v1/"

export async function getDevices(params) {
    try {
        const searchParams = new URLSearchParams(params);
        const instance = newHttpInstance("GET", `${API_URL}device?${searchParams}`)
        const res = await instance.request()
        return res
    } catch (error) {
        throw new Error(error)
    }
}

export async function getDevicesHistoryData(data) {
    try {
        const instance = newHttpInstance("POST", `${API_URL}device-custom-history`)
        const res = await instance.request({data})
        return res
    } catch (error) {
        throw new Error(error)
    }
}