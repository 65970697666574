import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { createThreshold } from "features/utils/threshold";
import { nanoid } from "nanoid";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

export function ThresholdFieldForm({ data, thresholds, deviceId }) {

    const defaultValues = {
        field: data.field,
        upper_limit: 0,
        ex_upper_limit: 0,
        lower_limit: 0,
        ex_lower_limit: 0,
        state: '0',
        device_id: deviceId,
    }
    const { handleSubmit, control, reset, register } = useForm({
        defaultValues
    })

    const onSubmit = (data) => {
        createThreshold(data).then(resp => {
            toast.info('更新成功')
        }).catch(err => {
            toast.error('無法更新資料')
        })
    }

    useEffect(() => {
        if (thresholds === undefined || thresholds === null) {
            return undefined
        }
        let newValue = defaultValues
        thresholds.forEach(v => {
            if (v.field !== defaultValues.field) {
                return
            }
            newValue = {
                ...defaultValues,
                upper_limit: v.upper_limit,
                ex_upper_limit: v.ex_upper_limit,
                lower_limit: v.lower_limit,
                ex_lower_limit: v.ex_lower_limit,
                state: v.state
            }
        })
        reset(newValue)
    }, [thresholds, data])

    return (
        <>
            <div className="font-medium bg-slate-200 py-2 p-2 flex items-center mx-auto max-w-container justify-between space-x-8 text-sm leading-6 text-slate-900">
                <div className="flex space-x-8 pl-4 sm:pl-6 lg:pl-8">
                    {data.label}
                </div>
                <div className="flex space-x-8 pr-4 sm:pr-6 lg:pr-8">
                    <button
                        onClick={handleSubmit(onSubmit)}
                        className="bg-blue-400 hover:bg-blue-300 text-white font-bold py-1 px-3 borde rounded align-center w-20"
                    >
                        儲存
                    </button>
                </div>
            </div>
            <section className="flex pl-2 mt-4 justify-between gap-4">
                <div>
                    <p className="pb-2 mr-4">{data.field} 極上限閥值：</p>
                    <Controller
                        render={({ field }) =>
                            <TextField
                                type="number"
                                id="outlined-basic"
                                variant="outlined"
                                className="w-64 2xl:w-96"
                                {...field}
                            />}
                        name="ex_upper_limit"
                        control={control}
                    />
                </div>
                <div>
                    <p className="pb-2">{data.field} 極下限閥值：</p>
                    <Controller
                        render={({ field }) =>
                            <TextField
                                type="number"
                                id="outlined-basic"
                                variant="outlined"
                                className="w-64 2xl:w-96"
                                {...field}
                            />}
                        name="ex_lower_limit"
                        control={control}
                    />
                </div>

                <div>
                    <p className="pb-2">{data.field} 警示開關：</p>
                    <div className="inline-block p-3.5 w-64 2xl:w-96">
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input type="checkbox" value="" className="sr-only peer" {...register("state")}/>
                            <div className="pt-6 w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                        </label>
                    </div>
                </div>
            </section>
            <section className="flex pl-2 mt-4 justify-between mb-4 gap-4">
                <div>
                    <p className="pb-2 mr-4">{data.field} 上限閥值：</p>
                    <Controller
                        render={({ field }) =>
                            <TextField
                                type="number"
                                id="outlined-basic"
                                variant="outlined"
                                className="w-64 2xl:w-96"
                                {...field}
                            />}
                        name="upper_limit"
                        control={control}
                    />
                </div>
                <div>
                    <p className="pb-2">{data.field} 下限閥值：</p>
                    <Controller
                        render={({ field }) =>
                            <TextField
                                type="number"
                                id="outlined-basic"
                                variant="outlined"
                                className="w-64 2xl:w-96"
                                {...field}
                            />}
                        name="lower_limit"
                        control={control}
                    />
                </div>

                <div className="invisible w-64 2xl:w-96" />
            </section>
        </>
    )
}