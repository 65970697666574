import BreadCrumb from "components/BreadCrumb"
import { VendorMetrics } from "components/predict-model/VendorMetrics"
import { getVendor, getVendorSettingTypes } from "features/utils"
import { getVendorAreas, getVendorMetrics } from "features/utils/vendor"
import { nanoid } from "nanoid"
import { useCallback, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { DatePicker } from "components/DatePicker"

export function TrainForm({
    handleOnSubmit,
    breadcrumbs,
    defaultValues
}) {
    const { handleSubmit, control, register, reset, setValue, formState: { errors } } = useForm({ defaultValues })
    const [vendors, setVendors] = useState([]);
    const [metricsGroup, setMetricsGroup] = useState([])
    const [areas, setAreas] = useState([])

    const handleFetchVendors = useCallback(() => {
        getVendorSettingTypes().then(resp => {
            setVendors(resp.data.data)
        }).catch(e => {
            toast.error(e.error)
        })
    }, [])

    const handleFetchVendorMetrics = useCallback((name) => {
        getVendorMetrics(name).then(resp => {
            setMetricsGroup(resp.data)
        }).catch(e => {
            toast.error(e.error)
        })
    }, [])

    const handleFetchDevices = useCallback((venderName) => {
        getVendorAreas(venderName).then(resp => {
            setAreas(resp.data)
        })
    }, [])

    const handleSelectAllDevices = (name, checked) => {
        if (!checked) {
            setValue(name, [])
            return
        }

        const fields = []
        metricsGroup.forEach(v => {
            v.data.forEach(d => {
                fields.push(`${v.group}_${d.field}`)
            })
        })
        setValue(name, fields)
    }

    const handleOnChangeVendor = (vendor => {
        handleFetchVendorMetrics(vendor);
        handleFetchDevices(vendor)
    })

    useEffect(() => {
        handleFetchVendors()
    }, [handleFetchVendors])

    useEffect(() => {
        if (defaultValues.vendor !== null && defaultValues.vendor !== undefined) {
            handleOnChangeVendor(defaultValues.vendor)
        }

        reset(defaultValues)
    }, [defaultValues])

    return (
        <>
            <h1 className="text-2xl font-bold my-3">預測模式</h1>
            <BreadCrumb data={breadcrumbs} />
            <form onSubmit={handleSubmit(handleOnSubmit)} className="bg-slate-100 p-4 rounded">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        訓練模型名稱
                    </label>
                    <input
                        className={`shadow appearance-none border rounded w-50 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name && 'bg-red-200'}`}
                        id="name"
                        type="text"
                        placeholder="請輸入名稱"
                        {...register('name', { required: true })}
                    />
                </div>
                <div className="flex gap-5 justify-start mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        感測器
                    </label>
                    {
                        vendors.map((v, index) =>
                            <div className="form-check form-check-inline" key={v.setting.alias}>
                                <Controller
                                    name="vendor"
                                    control={control}
                                    render={({
                                        field: { onChange, value, name },
                                        fieldState: { error }
                                    }) => (
                                        <input
                                            className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                            type="radio"
                                            id={v.setting.name}
                                            name={name}
                                            value={v.setting.name}
                                            onClick={(v) => {
                                                const vendor = v.target.value
                                                handleOnChangeVendor(vendor)
                                                onChange(vendor)
                                            }}
                                            checked={value === v.setting.name}
                                            {...register('vendor', { required: true })}
                                        />
                                    )}
                                />
                                <label className="form-check-label inline-block text-gray-800" htmlFor={v.setting.name}>{v.setting.alias}</label>
                            </div>
                        )
                    }
                    {errors.vendor && <span className="text-red-500">請選擇一個感測器</span>}
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        預測單位
                    </label>
                    {areas.map(a =>
                        <div className="flex gap-5 justify-start mb-3" key={nanoid()}>
                            <label className="block text-gray-700 text-sm font-bold mb-2 ml-10" htmlFor="name">
                                {a.name}
                            </label>
                            {
                                a.devices.map(d => (
                                    <div key={`devices_${d.id}`} className="form-check form-check-inline">
                                        <input
                                            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                            type="checkbox"
                                            key={d.id}
                                            id={`devices_${d.id}`}
                                            value={d.id}
                                            name="devices"
                                            {...register('devices')}
                                        />
                                        <label className="form-check-label inline-block text-gray-800" htmlFor={`devices_${d.id}`}>
                                            {d.name}
                                        </label>
                                    </div>
                                ))
                            }
                        </div>)
                    }
                    {errors.device && <span className="text-red-500">請選擇一個感測器</span>}
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="model">
                        AI 預測模型
                    </label>
                    <div className="inline-block relative w-64">
                        <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" {...register('model')}>
                            <option value="XGBOOST" >XGBOOST</option>
                            <option value="GRU" >GRU</option>
                            <option value="LSTM" >LSTM</option>
                            <option value="RandomForest" >RandomForest</option>
                            <option value="Catboost" >Catboost</option>
                            <option value="LightGBM" >LightGBM</option>
                        </select>
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        是否需經過PCA
                    </label>
                    <label htmlFor="red-toggle" className="inline-flex relative items-center mr-5 cursor-pointer">
                        <input type="checkbox" value="" id="red-toggle" className="sr-only peer" {...register('pca')} />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600" />
                    </label>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        日期
                    </label>
                    <div className="inline-block mr-5">
                        <DatePicker control={control} name="begin_time" label="開始時間" />
                    </div>
                    <div className="inline-block mr-5">
                        <DatePicker control={control} name="end_time" label="結束時間" />
                    </div>
                </div>
                <VendorMetrics title="訓練特徵值" handleSelectAll={e => {
                    const { checked } = e.target
                    handleSelectAllDevices("features", checked)
                }} register={register} group={metricsGroup} name="features" />
                <VendorMetrics title="訓練目標值" handleSelectAll={e => {
                    const { checked } = e.target
                    handleSelectAllDevices("targets", checked)
                }} register={register} group={metricsGroup} name="targets" />
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        數據集比例
                    </label>
                    <div className="flex gap-2">
                        <input
                            className={`shadow appearance-none border rounded w-50 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name && 'bg-red-200'}`}
                            id="training_set"
                            type="text"
                            placeholder="訓練集"
                            {...register('training_set', { required: true })}
                        />
                        <input
                            className={`shadow appearance-none border rounded w-50 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name && 'bg-red-200'}`}
                            id="test_set"
                            type="text"
                            placeholder="測試集"
                            {...register('test_set', { required: true })}
                        />
                    </div>
                </div>
                <div className="space-x-4 mt-5">
                    <button
                        type="submit"
                        className="w-50 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary-600 rounded-md hover:bg-primary-300 focus:outline-none focus:bg-primary-300 mt-12"
                    >
                        送出
                    </button>
                </div>
            </form>
        </>
    )
}