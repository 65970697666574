import {
  Breadcrumbs,
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material"
import MUIDataTable from "mui-datatables"
import ClearIcon from "@mui/icons-material/Clear"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { getVendors, getVendor, updateVendor } from "features/utils/vendor-setting"
import { useCallback, useEffect, useState } from "react"
import Dialog from "@mui/material/Dialog"
import { Controller, useForm } from "react-hook-form"
import Spinner from "components/Spinner"

function Vendor() {
  const [vendorData, setVendorData] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [loadingDisplay, setLoadingDisplay] = useState(false)
  const [previousName, setPreviousName] = useState("")

  const { handleSubmit, control, register, setValue } = useForm({
    defaultValues: {
      alias: "",
      categories: "",
      state: "",
    },
  })

  const handleClickOpen = () => {
    setDialogOpen(true)
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  const handleGetVendor = useCallback(() => {
    getVendors().then((resp) => {
      setVendorData(resp)
    })
  }, [])

  useEffect(() => {
    handleGetVendor()
  }, [])

  const numToString = (value) => value.map((v) => `${v}`)

  const modifySetting = (name) => {
    setLoadingDisplay(true)
    getVendor(name)
      .then((resp) => {
        setPreviousName(resp.name)
        setValue("alias", resp.alias)
        setValue("state", resp.state)
        setValue("categories", numToString(resp.categories))
      })
      .then(() => {
        setLoadingDisplay(false)
        handleClickOpen()
      })
  }

  const formatedCompanyData = vendorData?.map(({ name, alias, state }) => {
    const stateTrans = (state) => {
      console.log(state)
      if (state === "0" || state === 0) return "無"
      if (state === "1" || state === 1) return "有"
    }
    return [
      name,
      alias,
      stateTrans(state),
      <button
        onClick={() => modifySetting(name)}
        className="w-20 px-3 py-1 font-bold text-white bg-gray-400 rounded hover:bg-gray-300 borde align-center"
      >
        修改
      </button>,
    ]
  })

  const submitData = (data) => {
    handleClose()
    setLoadingDisplay(true)
    updateVendor(previousName, data)
      .then(() => {
        handleGetVendor()
      })
      .then(() => {
        setLoadingDisplay(false)
      })
  }

  return (
    <>
      {loadingDisplay && <Spinner />}
      <h1 className="my-3 text-2xl font-bold">廠商裝置管理</h1>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="py-2"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <MUIDataTable title="裝置" data={formatedCompanyData} columns={columns} options={options} />

      {dialogOpen && (
        <Dialog
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="px-4 pt-4">
            <div className="flex justify-between">
              <h1 className="mb-6 text-2xl font-bold">裝置設定</h1>
              <ClearIcon className="cursor-pointer" onClick={() => handleClose()} />
            </div>
            <form onSubmit={handleSubmit(submitData)}>
              <Controller
                name="alias"
                control={control}
                render={({ field }) => (
                  <div className="pb-6">
                    <TextField {...field} variant="outlined" label="裝置別名" />
                  </div>
                )}
              />
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    <div className="w-full pb-2">
                      <p className="pb-2 text-xl font-bold">狀態</p>
                      <FormControlLabel value="0" control={<Radio />} label="關" />
                      <FormControlLabel value="1" control={<Radio />} label="開" />
                    </div>
                  </RadioGroup>
                )}
              />
              <p className="pb-2 text-xl font-bold">類型</p>
              <FormGroup>
                {STATE_TYPE.map((v) => (
                  <div className="flex">
                    <input
                      className="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                      type="checkbox"
                      value={v.state}
                      name="categories"
                      {...register("categories")}
                    />
                    <label className="inline-block text-gray-800 form-check-label">{v.label}</label>
                  </div>
                ))}
              </FormGroup>

              <DialogActions>
                <Button type="submit">送出</Button>
              </DialogActions>
            </form>
          </div>
        </Dialog>
      )}
    </>
  )
}

export default Vendor

const breadcrumbs = [
  <Typography key="1" color="text.primary">
    系統設定
  </Typography>,
  <Typography key="2" color="text.secondary">
    廠商裝置管理
  </Typography>,
]
const columns = ["裝置名稱", "裝置別名", "類型", "操作"]

const options = {
  responsive: "standard",
  filterType: "checkbox",
  selectableRows: false,
  print: false,
  download: false,
}

const STATE_TYPE = [
  { state: 1, label: "水質" },
  { state: 2, label: "氣象" },
  { state: 3, label: "影像" },
]
