import axios from "axios"

/* Http Instance request */
export function newHttpInstance(method, url) {
  // let token = localStorage.getItem('access_token')

  const option = {
    method,
    baseURL: url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      // 'Authorization': `Bearer ${token}`
    },
  }
  /* Create a Basic HTTP config */
  const instance = axios.create(option)
  /* Add Error Handler */
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        // localStorage.removeItem('user')
        window.location.href = "/login"
      }
      return Promise.reject(error)
    }
  )
  return instance
}
