import { nanoid } from "nanoid"
import { useEffect, useState } from "react"

export function VendorMetrics({ register, handleSelectAll, title, name, group }) {

    const checkboxClass = "form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"

    return (
        <>
            <div className="flex gap-5 justify-start">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                    {title}
                </label>
                <div className="form-check form-check-inline">
                    <input
                        className={checkboxClass}
                        type="checkbox"
                        id="SELECT_ALL"
                        name={name}
                        onChange={handleSelectAll}
                    />
                    <label className="form-check-label inline-block text-gray-800" htmlFor="SELECT_ALL">
                        全選
                    </label>
                </div>
            </div>
            {
                group.map(v => (
                    <div className="flex gap-5 justify-start mb-3" key={nanoid()}>
                        <label className="block text-gray-700 text-sm font-bold mb-2 ml-10" htmlFor="name">
                            {v.group}
                        </label>
                        {
                            v.data.map(dataValue => {
                                const id = `${v.group}_${dataValue.field}`
                                const checkboxName = `${name}_${id}`
                                return (
                                    <div key={dataValue.field} className="form-check form-check-inline">
                                        <input
                                            className={checkboxClass}
                                            type="checkbox"
                                            id={checkboxName}
                                            value={id}
                                            name={name}
                                            {...register(name)}
                                        />
                                        <label className="form-check-label inline-block text-gray-800" htmlFor={checkboxName}>
                                            {`${dataValue.label} ${dataValue.unit !== '' ? `(${dataValue.unit})` : ''}`}
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                ))
            }
        </>
    )
}