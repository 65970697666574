import { getChart } from "features/utils"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"

function useDeviceData(deviceId) {
  const { isFetching, isError, isLoading, error, data } = useQuery(
    "getChart",
    () => getChart(deviceId),
    {
      cacheTime: 0,
    }
  )

  const [deviceData, setDeviceData] = useState(null)

  useEffect(() => {
    setDeviceData(data?.data)
  }, [data])

  return { deviceData, isFetching, isError, isLoading, error }
}

export default useDeviceData
