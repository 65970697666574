import { newHttpInstance } from "features/utils/common/http"

const API_URL = "/api/v1/"

export async function getVendors() {
    try {
        const instance = newHttpInstance("GET", `${API_URL}vendor-setting`)
        const res = await instance.request()
        return res.data
    } catch (error) {
        throw new Error(error)
    }
}

export async function getVendor(vendorName) {
    try {
        const instance = newHttpInstance("GET", `${API_URL}vendor-setting/${vendorName}`)
        const res = await instance.request()
        return res.data
    } catch (error) {
        throw new Error(error)
    }
}

export async function updateVendor(name, data) {
    try {
        const instance = newHttpInstance("PUT", `${API_URL}vendor-setting/${name}`)
        const res = await instance.request({
            data
        })
        return res
    } catch (error) {
        throw new Error(error)
    }
}

export async function getCategoriesVendor(number) {
    try {
        const instance = newHttpInstance("GET", `${API_URL}vendor-setting/categories/${number}`)
        const res = await instance.request()
        return res.data.data
    }
    catch (error) {
        throw new Error(error)
    }
}

export async function getSpecVendor(name){
    try {
        const instance = newHttpInstance("GET", `${API_URL}device?vendor=${name}`)
        const res = await instance.request()
        return res.data
    }
    catch (error) {
        throw new Error(error)
    }
}
