import { newHttpInstance } from "features/utils/common/http"

const API_URL = "/api/v1/"

export async function getAIOT(areaId) {
    try {
        const instance = newHttpInstance("GET", `${API_URL}aiot/${areaId}`)
        const res = await instance.request()
        return res
    } catch (error) {
        throw new Error(error)
    }
}

export async function setAIOT(areaId, deviceIds) {
    try {
        const instance = newHttpInstance("PUT", `${API_URL}aiot`)
        const res = await instance.request({
            data: {
                device_ids: deviceIds,
                area_id: areaId
            }
        })
        return res
    } catch (error) {
        throw new Error(error)
    }
}

export async function deleteAIOT(areaId) {
    try {
        const instance = newHttpInstance("DELETE", `${API_URL}aiot/${areaId}`)
        const res = await instance.request()
        return res
    } catch (error) {
        throw new Error(error)
    }
}