import TextField from "@mui/material/TextField"
import RoleSelect from "./RoleSelect"

function ManagerForm({ managerInfo = "", onChange, handleClickOpen }) {
  return (
    <form className="mt-8" onSubmit={handleClickOpen}>
      <div>
        <TextField
          className="w-full"
          label="管理員名稱"
          variant="outlined"
          onChange={onChange}
          name="name"
          required
        />
      </div>
      <div className="mt-4">
        <TextField
          className="w-full"
          label="管理員信箱"
          variant="outlined"
          onChange={onChange}
          name="email"
          required
        />
      </div>
      <div className="mt-4">
        <TextField
          className="w-full"
          label="管理員帳號"
          variant="outlined"
          onChange={onChange}
          name="username"
          required
        />
      </div>
      <div className="mt-4">
        <TextField
          className="w-full"
          label="管理員密碼"
          type="password"
          variant="outlined"
          onChange={onChange}
          name="password"
          required
          autoComplete="off"
        />
      </div>
      <div className="mt-4">
        <TextField
          className="w-full"
          label="再次確認密碼"
          type="password"
          variant="outlined"
          onChange={onChange}
          autoComplete="off"
          name="confirmationPassword"
          required
        />
      </div>
      <div className="mt-4">
        <RoleSelect />
      </div>
      <button
        type="submit"
        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary-600 rounded-md hover:bg-primary-300 focus:outline-none focus:bg-primary-300 mt-12"
      >
        送出
      </button>
    </form>
  )
}

export default ManagerForm
