import { useQuery } from "react-query"
import MUIDataTable from "mui-datatables"
import Typography from "@mui/material/Typography"
import Spinner from "components/Spinner"
import { toast } from "react-toastify"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Link from "@mui/material/Link"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { getPermissions } from "features/utils"
import { transferWords } from "utils/Translation"

function Regulation() {
  // const columns = ["ID", "權限名稱", "權限描述"]
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        setCellProps: () => ({
          style: {
            padding: "16px 0 16px 40px",
          },
        }),
      },
    },
    {
      name: "name",
      label: "權限名稱",
      options: {
        setCellProps: () => ({
          style: {
            padding: "16px 0 16px 32px",
          },
        }),
      },
    },
    {
      name: "desc",
      label: "權限描述",
      options: {
        setCellProps: () => ({
          style: {
            padding: "16px 0 16px 32px",
          },
        }),
      },
    },
  ]

  const { data, error, isError, isLoading } = useQuery("permissions", getPermissions)

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      系統設定
    </Link>,
    <Typography key="3" color="text.primary">
      查看權限
    </Typography>,
  ]

  if (isLoading) {
    return <Spinner />
  }

  if (isError) {
    toast.error(error.message)
  }

  const records = data?.map((record) => [
    record?.id,
    transferWords(record?.name),
    record?.description,
  ])

  const options = {
    selectableRows: false,
    responsive: "standard",
  }
  return (
    <>
      <h1 className="my-3 text-2xl font-bold">查看權限</h1>
      {/* BreadCrumb */}
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="py-2"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <MUIDataTable title="權限列表" data={records} columns={columns} options={options} />
    </>
  )
}

export default Regulation
