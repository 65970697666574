import BreadCrumb from 'components/BreadCrumb'
import React from 'react'
import { Link } from 'react-router-dom'
import Typography from "@mui/material/Typography"
import GroupFormV2 from './Form/GroupFormV2'

function CreateGroupV2() {

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      系統設定
    </Typography>,
    <Typography key="2" color="text.primary">
      <Link to="/setting/group">權限管理</Link>
    </Typography>,
    <Typography key="3" color="text.secondary">
      新增權限群組
    </Typography>,
  ]
  
  const defaultValues = {
    name: "",
    description: "",
    permissions: [],
    area_ids: []
  };

  return (
    <>
      <h1 className="text-2xl font-bold my-3">新增權限群組</h1>

      <BreadCrumb data={breadcrumbs} />
      <GroupFormV2 defaultValues={defaultValues} formStatus="create"/>
    </>
  )
}

export default CreateGroupV2