import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField"

export function RedioTextField({ checked, label, control, name }) {
    return (
        <Controller
            render={({ field: {
                onChange,
                onBlur,
                value,
                ref
            } }) => (
                <TextField
                    disabled={!checked}
                    label={label}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value || ''}
                    inputRef={ref}
                    name={name}
                />
            )}
            name={name}
            control={control}
        />
    )
}