import { useEffect, useState } from "react"
import { SwitchAreaButton } from "./SwitchAreaButton"
import { ColumeLineChart } from "./ColumeLineChart"

export function HistoryChart({ respData }) {
    const [chartData, setChartData] = useState([])
    const [selectedFields, setSelectedFields] = useState([])
    const [openDialog, setOpenDialog] = useState(false)

    const handleCloseFieldSelected = () => {
        setOpenDialog(false)
    }

    const handleOpenFieldSelected = () => {
        setOpenDialog(true)
    }


    useEffect(() => {
        if (respData === null) {
            return
        }
        const { columns, data } = respData
        setChartData(data)
        const selected = []
        columns.forEach((v, index) => {
            let status = false
            if (index === 0) {
                status = true
            }
            selected.push({
                ...v,
                selected: status
            })
        })
        setSelectedFields(selected)
    }, [respData])

    const switchAllFields = (status) => {
        const newSelectedFields = []
        selectedFields.forEach(v => {
            newSelectedFields.push({
                ...v,
                selected: status
            })
        })
        setSelectedFields(newSelectedFields)
    }

    const handleOnOpenAllFields = () => {
        switchAllFields(true)
    }

    const handleOnCloseAllFields = () => {
        switchAllFields(false)
    }

    const handleSwitchFields = (e) => {
        const { checked, value } = e.target
        const newSelectedFields = []
        selectedFields.forEach(v => {
            let status = v.selected
            if (v.field === value) {
                status = checked
            }
            newSelectedFields.push({
                ...v,
                selected: status
            })
        })
        setSelectedFields(newSelectedFields)
    }

    return (
        <div>
            <button
                onClick={handleOpenFieldSelected}
                className="px-3 py-1 font-bold text-white bg-blue-400 rounded hover:bg-blue-300 align-center mt-5"
            >
                開啟欄位設定
            </button>
            <div className="flex">
                <ColumeLineChart
                    selectedFields={selectedFields}
                    chartData={chartData}
                />
            </div>
            <SwitchAreaButton
                fieldSelected={openDialog}
                selectedFields={selectedFields}
                handleCloseFieldSelected={handleCloseFieldSelected}
                handleOnDisplayColumn={handleSwitchFields}
                handleCloseAllSelect={handleOnCloseAllFields}
                handleOpenAllSelect={handleOnOpenAllFields}
            />
        </div>
    )
}