import MUIDataTable from "mui-datatables"
import Typography from "@mui/material/Typography"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Link from "@mui/material/Link"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import useDatabase from "hooks/useDatabase"
import { useEffect, useState } from "react"

function Dosage() {
  const [database] = useDatabase()
  const columns = ["資料庫", "用量"]
  const [data, setData] = useState([])

  const createData = (row) => [row.name, row.size]

  useEffect(() => {
    if (database == null) {
      return
    }
    const newData = database.map((v) => createData(v))
    setData(newData)
  }, [database])

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      系統設定
    </Typography>,
    <Typography key="2" color="text.secondary">
      資料表用量
    </Typography>,
  ]

  const options = {
    responsive: "standard",
    filterType: "checkbox",
    selectableRows: false,
    print: false,
    download: false,
  }
  return (
    <>
      <h1 className="my-3 text-2xl font-bold">資料表用量</h1>
      {/* BreadCrumb */}
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="py-2"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <MUIDataTable title="資料庫" data={data} columns={columns} options={options} />
    </>
  )
}

export default Dosage
