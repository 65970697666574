import { newHttpInstance } from "features/utils/common/http"

const API_URL = "/api/v1/"

export async function getAreas(params) {
    try {
        const searchParams = new URLSearchParams(params);
        const instance = newHttpInstance("GET", `${API_URL}areas?${searchParams}`)
        const res = await instance.request()
        return res
    } catch (error) {
        throw new Error(error)
    }
}