import { getDatabase } from "features/utils"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"

function useDatabase() {
    const { isLoading, isError, error, isFetching, data } = useQuery("getDatabase", () => getDatabase())

    const [database, setDatabase] = useState(null)

    useEffect(() => {
        setDatabase(data?.data)
    }, [data])

    return [database, setDatabase, isLoading, isError, error, isFetching]
}

export default useDatabase
