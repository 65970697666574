import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis, ResponsiveContainer, Label, Legend } from "recharts"
import moment from "moment"
import { colorArray, defaultCreateAt } from "./Setting"

export function ColumeLineChart({ chartData, selectedFields }) {

    const formatXAxis = (tickItem) => moment(tickItem).format('YYYY-MM-DD HH:mm:ss')

    return (
        <ResponsiveContainer aspect={3}>
            <LineChart width={950} height={350} data={chartData} className="my-4">
                <Legend />
                {
                    selectedFields.map((v, index) => {
                        if (v.field === defaultCreateAt) {
                            return
                        }
                        if (!v.selected) {
                            return
                        }
                        return (
                            <Line
                                key={v.field}
                                dataKey={v.field}
                                stroke={colorArray[index]}
                                yAxisId={index}
                                type="monotone"
                            />
                        )
                    })
                }
                {
                    selectedFields.map((v, index) => {
                        if (v.field === defaultCreateAt) {
                            return
                        }
                        if (!v.selected) {
                            return
                        }
                        return <YAxis
                            dataKey={v.field}
                            key={v.field}
                            type="number"
                            orientation="left"
                            yAxisId={index}
                            tick={{ fill: colorArray[index] }}>
                            <Label value={v.label} angle={-90} position="end" />
                        </YAxis>
                    })
                }
                <XAxis dataKey={defaultCreateAt} tickFormatter={formatXAxis} />
                <Tooltip position={{ y: 200 }} />
                <CartesianGrid stroke="#f5f5f5" />
            </LineChart>
        </ResponsiveContainer>
    )
}