import "../index.css"

function Spinner() {
  return (
    <div className="loadingSpinnerContainer">
      <div className="loadingSpinner" />
    </div>
  )
}

export default Spinner
