import BreadCrumb from "components/BreadCrumb"
import Typography from "@mui/material/Typography"
import { createEvent, getEvent } from "features/utils/event";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { EventForm } from './EventForm';

const defaultValues = {
    "immediate": true,
    "time": "10/12/2022 04:20 PM",
    "message": "",
    "device_id": 0,
    "state": 1
}

function EditEvent() {
    const params = useParams();
    const navigate = useNavigate();
    const [value, setValue] = useState(defaultValues)

    const breadcrumbs = [
        <Typography key="1" color="text.primary">
            系統設定
        </Typography>,
        <Typography key="2" color="text.secondary">
            <Link to="/setting/event">警示設定</Link>
        </Typography>,
        <Typography key="3" color="text.secondary">
            編輯警示
        </Typography>,
    ]

    const fetchEvent = useCallback(() => {
        getEvent(params.id).then(resp => setValue(resp.data))
    }, [])

    const handleOnSubmit = data => {
        // console.log(data)
    }

    useEffect(() => {
        fetchEvent()
    }, [])

    return (
        <>
            <h1 className="text-2xl font-bold my-3">編輯警示</h1>
            <BreadCrumb data={breadcrumbs} />
            <EventForm
                onSubmit={handleOnSubmit}
                defaultValues={value}
            />
        </>

    )
}

export default EditEvent