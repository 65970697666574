import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  permissionList: [],
  selectAll: false,
}

export const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    changeSelectAll: (state) => {
      state.selectAll = !state.selectAll
    },
    addPermission: (state, action) => {
      state.permissionList = [...state.permissionList, action.payload]
    },
    addPermissions: (state, action) => {
      state.permissionList = [...state.permissionList, ...action.payload]
    },
    removePermission: (state, action) => {
      state.permissionList = state.permissionList.filter((v) => v !== action.payload)
    },
    clearPermissions: (state) => {
      state.permissionList = []
    },
    initialPermission: (state) => {
      state.selectAll = false
      state.permissionList = []
    },
  },
})

export const {
  addPermission,
  removePermission,
  changeSelectAll,
  clearPermissions,
  addPermissions,
  initial,
} = permissionSlice.actions
export default permissionSlice.reducer
