import { useQuery } from 'react-query'
import { getRolesById } from 'features/utils'
import BreadCrumb from 'components/BreadCrumb'
import { Link, useParams } from 'react-router-dom'
import Typography from "@mui/material/Typography"
import GroupFormV2 from './Form/GroupFormV2'

function EditGroupV2() {
    const params = useParams()
    const roleId = params.id

    const breadcrumbs = [
        <Typography key="1" color="text.primary">
            系統設定
        </Typography>,
        <Typography key="2" color="text.primary">
            <Link to="/setting/group">權限管理</Link>
        </Typography>,
        <Typography key="3" color="text.secondary">
            編輯權限群組
        </Typography>,
    ]

    const { data, isLoading, isError, error } = useQuery(
        "getRolesById",
        () => getRolesById(roleId),
        {
            cacheTime: 0,
        }
    )

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (isError) {
        return <div>Error: {error.message}</div>
    }

    const defaultValues = {
        name: data?.data?.name,
        description: data?.data?.description,
        permissions: data?.data?.permissions,
        areas: data?.data?.areas
    };

    return (
        <>
            <h1 className="text-2xl font-bold my-3">編輯權限群組</h1>

            <BreadCrumb data={breadcrumbs} />
            <GroupFormV2 defaultValues={defaultValues} formStatus="edit" roleId={roleId}/>
        </>
    )
}

export default EditGroupV2