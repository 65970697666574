import { TextField } from "@mui/material"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { useState } from "react"
import { Controller } from "react-hook-form"
import { toast } from "react-toastify"
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined"

const TOKEN = "AIzaSyCx9Gh297zHfq7k48KjWk0NTe_kQVkLRNU"

function MapContent({ control, getValues, setValue }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: TOKEN,
  })

  if (!isLoaded) return <div>Loading...</div>

  return <Map control={control} getValues={getValues} setValue={setValue} />
}

function Map({ control, getValues, setValue }) {
  const defaultLat = Number(getValues("latitude"))
  const defaultLong = Number(getValues("longitude"))

  const [lat, setLat] = useState({ lat: defaultLat, lng: defaultLong })

  const center = { lat: lat.lat, lng: lat.lng }

  const getAddressByLatAndLong = (lat, long) => {
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${TOKEN}`)
      .then((response) => response.json())
      .then((data) => setLat(data.results[0].geometry.location))
      .catch((err) => toast.error("經位度輸入錯誤，無法定位 Google Map"))
  }

  const getAddressByAddress = (address) => {
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${TOKEN}`)
      .then((response) => response.json())
      .then((data) => setAddressLatValue(data))
      .catch((err) => toast.error("無法定位 Google Map"))
  }

  const setAddressLatValue = (value) => {
    setLat(value.results[0].geometry.location)
     setValue("latitude", value.results[0].geometry.location.latitude)
     setValue("longitude", value.results[0].geometry.location.longitude)
  }

  const handleGetAddress = () => {
    const latitude = getValues("latitude")
    const longitude = getValues("longitude")
    const city = getValues("city")
    const district = getValues("district")
    const address = getValues("address")
    const allAddress = `${city}${district}${address}`
    if (latitude === '' || longitude === '' || Number.isNaN(Number(latitude)) || Number.isNaN(Number(longitude)) || allAddress !== "") {
      getAddressByAddress(allAddress)
    } else {
      getAddressByLatAndLong(Number(latitude), Number(longitude))
    }
  }

  return (
    <>
      <div className="flex gap-2 mb-4">
        <Controller
          render={({ field, formState: { errors } }) => (
            <TextField
              className="w-60"
              label="*縣市"
              variant="outlined"
              error={!!errors.city}
              {...field}
            />
          )}
          name="city"
          control={control}
          rules={{ required: true }}
        />
        <Controller
          render={({ field, formState: { errors } }) => (
            <TextField
              className="w-60"
              label="*鄉鎮市區"
              variant="outlined"
              error={!!errors.district}
              {...field}
            />
          )}
          name="district"
          control={control}
          rules={{ required: true }}
        />
        <Controller
          render={({ field, formState: { errors } }) => (
            <TextField
              className="w-60"
              label="*地址"
              variant="outlined"
              error={!!errors.address}
              {...field}
            />
          )}
          name="address"
          control={control}
          rules={{ required: true }}
        />
      </div>
      <div className="flex gap-2">
        <Controller
          render={({ field, formState: { errors } }) => (
            <TextField
              className="w-60"
              label="*經度"
              variant="outlined"
              error={!!errors.address}
              {...field}
            />
          )}
          name="latitude"
          control={control}
          rules={{ required: true }}
        />
        <Controller
          render={({ field, formState: { errors } }) => (
            <TextField
              className="w-60"
              label="*緯度"
              variant="outlined"
              error={!!errors.address}
              {...field}
            />
          )}
          name="longitude"
          control={control}
          rules={{ required: true }}
        />
        <div
          onClick={handleGetAddress}
          type="submit"
          className="w-60 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary-600 rounded-md hover:bg-primary-300 focus:outline-none focus:bg-primary-300 flex items-center justify-center cursor-pointer"
        >
          <FmdGoodOutlinedIcon className="mr-1" />
          定位
        </div>
      </div>
      <div className="pt-6">
        <GoogleMap zoom={15} center={center} mapContainerClassName="map-container">
          <MarkerF position={center} />
        </GoogleMap>
      </div>
    </>
  )
}

export default MapContent
