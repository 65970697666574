import { useEffect, useState } from "react"
import { useParams, Link, useNavigate } from "react-router-dom"
import { useMutation, useQuery } from "react-query"
import { useSelector } from "react-redux"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import Tabs from "@mui/material/Tabs"
import TabPanel from "@mui/lab/TabPanel"
import { toast } from "react-toastify"
import { getAdminById, putAdmin } from "features/utils"
import BreadCrumb from "components/BreadCrumb"
import CustomDialog from "components/dialog/CustomDialog"
import Spinner from "components/Spinner"
import ManagerInfoForm from "./Form/ManagerInfoForm"
import ManagerAccountForm from "./Form/ManagerAccountForm"
import RoleSelect from "./Form/RoleSelect"

function EditManager() {
  const params = useParams()
  const managerId = params.id
  const navigate = useNavigate()
  const [value, setValue] = useState("1")
  const [managerInfo, setManagerInfo] = useState({
    name: "",
    email: "",
    username: "",
    password: "",
    confirmationPassword: "",
  })
  const { role } = useSelector((state) => state.role)

  const [open, setOpen] = useState(false)
  const handleClickOpen = (e) => {
    e.preventDefault()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const { isFetching, isError, isLoading, error, data } = useQuery(
    "getAdminById",
    () => getAdminById(managerId),
    {
      cacheTime: 0,
    }
  )

  const { mutate } = useMutation((v) => putAdmin(v.submitData, v.id), {
    onSuccess: () => {
      navigate(`/setting/manager`)
      toast.success("修改成功")
    },
  })

  useEffect(() => {
    setManagerInfo(data?.data)
  }, [isFetching])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const onChange = (e) => {
    setManagerInfo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onEditSubmit = (e) => {
    e.preventDefault()
    let submitData = {}
    switch (value) {
      case "1":
        submitData = {
          name: managerInfo.name,
          email: managerInfo.email,
        }
        break
      case "2":
        submitData = {
          password: managerInfo.password,
          password_confirmation: managerInfo.confirmationPassword,
        }
        break
      case "3":
        submitData = {
          role,
        }
        break
      default:
        break
    }
    mutate({ submitData, id: managerId })
    setOpen(false)
  }

  if (isError) {
    toast.error(error.message)
  }

  if (isLoading) {
    return <Spinner />
  }

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      系統設定
    </Typography>,
    <Typography key="2" color="text.primary">
      <Link to="/setting/manager">登入帳號管理</Link>
    </Typography>,
    <Typography key="3" color="text.secondary">
      編輯登入帳號
    </Typography>,
  ]

  return (
    <>
      <CustomDialog handleClose={handleClose} method={onEditSubmit} open={open} content="修改" />
      <h1 className="text-2xl font-bold my-3">編輯登入帳號</h1>

      {/* BreadCrumb */}
      <BreadCrumb data={breadcrumbs} />

      {/* Tab */}
      <Box sx={{ width: "100%", typography: "body1" }} className="my-3">
        <TabContext value={value}>
          <Tabs
            sx={{ borderBottom: 1, borderColor: "divider" }}
            value={value}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
            onChange={handleChange}
          >
            <Tab label="編輯基本資訊" value="1" />
            <Tab label="編輯帳號密碼" value="2" />
            <Tab label="編輯權限角色" value="3" />
          </Tabs>

          <TabPanel value="1" className="mx-auto">
            {managerInfo ? (
              <ManagerInfoForm
                managerInfo={managerInfo}
                onChange={onChange}
                handleClickOpen={handleClickOpen}
              />
            ) : (
              <Spinner />
            )}
          </TabPanel>
          <TabPanel value="2" className="mx-auto">
            {managerInfo ? (
              <ManagerAccountForm
                managerInfo={managerInfo}
                onChange={onChange}
                handleClickOpen={handleClickOpen}
              />
            ) : (
              <Spinner />
            )}
          </TabPanel>
          <TabPanel value="3" className="mx-auto">
            {managerInfo ? (
              <form className="mt-8" onSubmit={handleClickOpen}>
                <div className="mt-4">
                  <RoleSelect selected={managerInfo.role} />
                </div>
                <button
                  type="submit"
                  className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary-600 rounded-md hover:bg-primary-300 focus:outline-none focus:bg-primary-300 mt-12"
                >
                  送出
                </button>
              </form>
            ) : (
              <Spinner />
            )}
          </TabPanel>
        </TabContext>
      </Box>
    </>
  )
}

export default EditManager
