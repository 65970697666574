import BreadCrumb from "components/BreadCrumb"
import { VendorMetrics } from "components/predict-model/VendorMetrics"
import { getVendor } from "features/utils"
import { getVendorAreas, getVendorMetrics } from "features/utils/vendor"
import { getVendors } from "features/utils/vendor-setting"
import { nanoid } from "nanoid"
import { useCallback, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { DatePicker } from "components/DatePicker"
import Dropzone from "react-dropzone";
import { uploadFile } from "features/utils/predict-model"


const validate = (data) => {
    if (data.source === '1' && data.csv_id === undefined) {
        toast.error('請上傳 CSV 檔案')
        return false
    }
    return true
}

export function PredictForm({
    handleOnSubmit,
    breadcrumbs,
    defaultValues,
    csvFile
}) {
    const { handleSubmit, control, register, reset, setValue, watch, formState: { errors } } = useForm({ defaultValues })
    const [vendors, setVendors] = useState([]);
    const [vendorSetting, setVendorSetting] = useState([]);
    const [metricsGroup, setMetricsGroup] = useState([])
    const [areas, setAreas] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [fileInfo, setFileInfo] = useState(null)
    const uploadMode = watch('source')

    const handleFetchVendors = useCallback(() => {
        getVendor().then(resp => {
            setVendors(resp.data)
        }).catch(e => {
            toast.error(e.error)
        })
    }, [])

    const handleFetchVendorSettings = useCallback(() => {
        getVendors()
            .then((resp) => {
            setVendorSetting(filterVendorSetting(resp))
        })
            .catch((e) => {
            toast.error(e.error)
        })
    })

    const filterVendorSetting = (vendor) => vendor.filter((v) => !v.categories.includes(3))

    const handleFetchVendorMetrics = useCallback((name) => {
        getVendorMetrics(name).then(resp => {
            setMetricsGroup(resp.data)
        }).catch(e => {
            toast.error(e.error)
        })
    }, [])

    const handleFetchDevices = useCallback((venderName) => {
        getVendorAreas(venderName).then(resp => {
            setAreas(resp.data)
        })
    }, [])

    const handleSelectAllDevices = (name, checked) => {
        if (!checked) {
            setValue(name, [])
            return
        }

        const fields = []
        metricsGroup.forEach(v => {
            v.data.forEach(d => {
                fields.push(`${v.group}_${d.field}`)
            })
        })
        setValue(name, fields)
    }

    const handleOnChangeVendor = (vendor => {
        handleFetchVendorMetrics(vendor);
        handleFetchDevices(vendor)
    })

    const handleOnFileUpload = async (fileList) => {
        const formData = new FormData();
        for (let i = 0; i < (fileList.length); i += 1) {
            formData.append("file", fileList[i]);
        }
        setIsLoading(true)
        uploadFile(formData).then((res) => {
            setFileInfo(res.data)
            setValue('csv_id', res.data.id)
        }).catch((err) => {
            console.error(`UploadFiles Someting error: ${err}`)
            toast.error('上傳CSV失敗, 請注意檔案格式')
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const onSubmit = (data) => {
        if (!validate(data)) {
            return
        }
        handleOnSubmit(data)
    }

    useEffect(() => {
        handleFetchVendors()
    }, [handleFetchVendors])

    useEffect(()=>{
        handleFetchVendorSettings()
    },[])

    useEffect(() => {
        if (defaultValues.vendor !== null && defaultValues.vendor !== undefined) {
            handleOnChangeVendor(defaultValues.vendor)
        }

        reset(defaultValues)
    }, [defaultValues])

    useEffect(() => {
        if (csvFile === null || csvFile === undefined) {
            return undefined
        }
        setFileInfo({
            name: csvFile?.original_name
        })
    }, [csvFile])

    return (
        <>
            <h1 className="my-3 text-2xl font-bold">預測模式</h1>
            <BreadCrumb data={breadcrumbs} />
            <form onSubmit={handleSubmit(onSubmit)} className="p-4 rounded bg-slate-100">
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="name">
                        訓練模型名稱
                    </label>
                    <input
                        className={`shadow appearance-none border rounded w-50 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name && 'bg-red-200'}`}
                        id="name"
                        type="text"
                        placeholder="請輸入名稱"
                        {...register('name', { required: true })}
                    />
                </div>

                <div className="flex justify-start gap-5 mb-4">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="name">
                        資料來源
                    </label>
                    <div className="form-check form-check-inline">
                        <Controller
                            name="source"
                            control={control}
                            render={({
                                field: { onChange, value, name },
                                fieldState: { error }
                            }) => (
                                <input
                                    className="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-full appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                                    type="radio"
                                    name={name}
                                    value="0"
                                    onClick={onChange}
                                    checked={value === "0"}
                                    {...register('source', { required: true })}
                                />
                            )}
                        />
                        <label className="inline-block text-gray-800 form-check-label">平台</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Controller
                            name="source"
                            control={control}
                            render={({
                                field: { onChange, value, name },
                                fieldState: { error }
                            }) => (
                                <input
                                    className="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-full appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                                    type="radio"
                                    name={name}
                                    value="1"
                                    onClick={onChange}
                                    checked={value === "1"}
                                    {...register('source', { required: true })}
                                />
                            )}
                        />
                        <label className="inline-block text-gray-800 form-check-label">CSV上傳</label>
                    </div>
                </div>

                {
                    uploadMode === '1' &&
                    <div className="flex mb-4">
                        <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="name">
                            上傳檔案
                            <br />
                            <a className="text-purple-600" href="/api/v1/ai-csv/example-file">下載範例檔案</a>
                        </label>
                        <div className="ml-4">
                            {
                                isLoading ?
                                    <button
                                        type="submit"
                                        className="px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-red-600 rounded-md cursor-pointer w-52 hover:bg-red-300 focus:outline-none focus:bg-red-300"
                                        disabled
                                    >
                                        上傳中
                                    </button> :
                                    <Dropzone
                                        onDrop={(acceptedFiles) => handleOnFileUpload(acceptedFiles)}
                                        className="dragFiles-dialog-dropzone"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div
                                                    {...getRootProps()}
                                                    className="px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-red-600 rounded-md cursor-pointer hover:bg-red-300 focus:outline-none focus:bg-red-300"
                                                >
                                                    <input {...getInputProps()} />
                                                    <p>上傳CSV檔案</p>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                            }
                        </div>
                        <div className="ml-4">
                            {fileInfo?.name}
                        </div>
                    </div>
                }
                {
                    uploadMode === '0' &&
                    <div>
                        <div className="flex justify-start gap-5 mb-4">
                            <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="name">
                                感測器
                            </label>
                            {
                                // vendors.map((v, index) =>
                                vendorSetting.map((v, index) =>
                                    <div className="form-check form-check-inline" key={v.name}>
                                        <Controller
                                            name="vendor"
                                            control={control}
                                            render={({
                                                field: { onChange, value, name },
                                                fieldState: { error }
                                            }) => (
                                                <input
                                                    className="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-full appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                                                    type="radio"
                                                    id={v.name}
                                                    name={name}
                                                    value={v.name}
                                                    onClick={(v) => {
                                                        const vendor = v.target.value
                                                        handleOnChangeVendor(vendor)
                                                        onChange(vendor)
                                                    }}
                                                    checked={value === v.name}
                                                    {...register('vendor', { required: true })}
                                                />
                                            )}
                                        />
                                        <label className="inline-block text-gray-800 form-check-label" htmlFor={v.name}>{v.alias}</label>
                                    </div>
                                )
                            }
                            {errors.vendor && <span className="text-red-500">請選擇一個感測器</span>}
                        </div>

                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="name">
                                預測單位
                            </label>
                            {areas.map(a =>
                                <div className="flex justify-start gap-5 mb-3" key={nanoid()}>
                                    <label className="block mb-2 ml-10 text-sm font-bold text-gray-700" htmlFor="name">
                                        {a.name}
                                    </label>
                                    {
                                        a.devices.map(d => (
                                            <div key={`devices_${d.id}`} className="form-check form-check-inline">
                                                <input
                                                    className="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                                                    type="checkbox"
                                                    key={d.id}
                                                    id={`devices_${d.id}`}
                                                    value={d.id}
                                                    name="devices"
                                                    {...register('devices')}
                                                />
                                                <label className="inline-block text-gray-800 form-check-label" htmlFor={`devices_${d.id}`}>
                                                    {d.name}
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>)
                            }
                            {errors.device && <span className="text-red-500">請選擇一個感測器</span>}
                        </div>
                    </div>
                }
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="model">
                        AI 預測模型
                    </label>
                    <div className="relative inline-block w-64">
                        <select className="block w-full px-4 py-2 pr-8 leading-tight bg-white border border-gray-400 rounded shadow appearance-none hover:border-gray-500 focus:outline-none focus:shadow-outline" {...register('model')}>
                            <option value="XGBOOST" >XGBOOST</option>
                            <option value="GRU" >GRU</option>
                            <option value="LSTM" >LSTM</option>
                            <option value="RandomForest" >RandomForest</option>
                            <option value="Catboost" >Catboost</option>
                            <option value="LightGBM" >LightGBM</option>
                        </select>
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="name">
                        是否需經過PCA
                    </label>
                    <label htmlFor="red-toggle" className="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" value="" id="red-toggle" className="sr-only peer" {...register('pca')} />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600" />
                    </label>
                </div>
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="name">
                        日期
                    </label>
                    <div className="inline-block mr-5">
                        <DatePicker control={control} name="begin_time" label="開始時間" />
                    </div>
                    <div className="inline-block mr-5">
                        <DatePicker control={control} name="end_time" label="結束時間" />
                    </div>
                </div>
                {
                    uploadMode === '0' &&
                    <VendorMetrics title="訓練特徵值" handleSelectAll={e => {
                        const { checked } = e.target
                        handleSelectAllDevices("features", checked)
                    }} register={register} group={metricsGroup} name="features" />
                }
                {
                    uploadMode === '0' &&
                    <VendorMetrics title="訓練目標值" handleSelectAll={e => {
                        const { checked } = e.target
                        handleSelectAllDevices("targets", checked)
                    }} register={register} group={metricsGroup} name="targets" />
                }
                <div className="mt-5 space-x-4">
                    <button
                        type="submit"
                        className="px-4 py-2 mt-12 tracking-wide text-white transition-colors duration-200 transform rounded-md w-52 bg-primary-600 hover:bg-primary-300 focus:outline-none focus:bg-primary-300"
                    >
                        送出
                    </button>
                </div>
            </form>
        </>
    )
}