import { newHttpInstance } from "features/utils/common/http"

const API_URL = "/api/v1/"

export async function createEvent(data) {
    try {
        const instance = newHttpInstance("POST", `${API_URL}events`)
        const res = await instance.request({
            data
        })
        return res
    } catch (error) {
        throw new Error(error)
    }
}

export async function getEvents() {
    try {
        const instance = newHttpInstance("GET", `${API_URL}events`)
        const res = await instance.request()
        return res
    } catch (error) {
        throw new Error(error)
    }
}

export async function getEvent(id) {
    try {
        const instance = newHttpInstance("GET", `${API_URL}events/${id}`)
        const res = await instance.request()
        return res
    } catch (error) {
        throw new Error(error)
    }
}