import { useQuery, useMutation } from "react-query"
import { useSelector } from "react-redux"
import MUIDataTable from "mui-datatables"
import Typography from "@mui/material/Typography"
import Spinner from "components/Spinner"
import { toast } from "react-toastify"
import { getArea, deleteArea } from "features/utils"
import AddButton from "components/button/AddButton"
import EditButton from "components/button/EditButton"
import { useCallback, useEffect, useState } from "react"
import CustomDialog from "components/dialog/CustomDialog"
import { useNavigate } from "react-router-dom"
import BreadCrumb from "components/BreadCrumb"
import moment from "moment"

function Area() {
  const { ownPermissionList, ownAreaList } = useSelector((state) => state.ownPermission)
  const [areas, setAreas] = useState([])

  const handleFetchArea = useCallback((list) => {
    getArea({
      only: list
    }).then(resp => {
      setAreas(resp.data)
    })
  }, [])

  const [deleteId, setDeleteId] = useState({ ids: [] })
  const { mutate } = useMutation(() => deleteArea(deleteId), {
    onSuccess: () => {
      handleFetchArea()
      toast.success("刪除成功")
    },
    onError: (err) => {
      toast.error(err)
    },
  })
  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      系統設定
    </Typography>,
    <Typography key="2" color="text.secondary">
      設定場域
    </Typography>,
  ]

  // Add Button Navigate
  const navigate = useNavigate()
  const navigateToAddArea = () => {
    navigate("/setting/area/create")
  }

  const records = areas?.records?.map((record) => [
      record?.id,
      record?.name,
      record?.description,
      moment(record?.created_at).format("YYYY-MM-DD hh:mm:ss"),
      <div className="flex gap-4">
        {ownPermissionList.includes("AREA_UPDATE") && (
          <button
            onClick={() => {
              navigate(`/setting/area/${record?.id}/edit`)
            }}
            className="w-20 px-3 py-1 font-bold text-white bg-gray-400 rounded hover:bg-gray-300 borde align-center"
          >
            修改
          </button>
        )}
        <button
          onClick={() => {
            navigate(`/setting/area/${record?.id}/edit?target=2`)
          }}
          className="w-20 px-3 py-1 font-bold text-white bg-blue-400 rounded hover:bg-blue-300 borde align-center"
        >
          感測器
        </button>
      </div>,
    ])

  // Dialog
  const [open, setOpen] = useState(false)
  const handleClickOpen = (deleteId) => {
    setOpen(true)
    const indexes = deleteId.data.map((x) => x.dataIndex)
    const ids = []
    indexes.forEach((i) => {
      ids.push(records[i][0])
    })
    const result = { ids }
    setDeleteId(result)
  }

  const deleteRow = () => {
    mutate(deleteId)
    setOpen(false)
    setDeleteId({ ids: [] })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const columns = ["ID", "名稱", "描述", "新增時間", "修改"]

  const options = {
    filterType: "checkbox",
    print: false,
    download: false,
    selectableRowsOnClick: true,
    responsive: "standard",
    onRowsDelete: (rowsDeleted) => {
      handleClickOpen(rowsDeleted)
    },
  }

  if (!ownPermissionList.includes("AREA_DELETE")) {
    options.customToolbarSelect = () => {}
  }

  useEffect(() => {
    if (ownAreaList?.length === 0) {
      return
    }
    handleFetchArea(ownAreaList)
  }, [handleFetchArea, ownAreaList])

  return (
    <>
      <CustomDialog open={open} method={deleteRow} handleClose={handleClose} content="刪除" />
      <h1 className="my-3 text-2xl font-bold">設定場域</h1>
      {/* BreadCrumb */}
      <BreadCrumb data={breadcrumbs} />

      {/* Add Button */}
      <div className="flex justify-end">
        {ownPermissionList.includes("AREA_CREATE") && (
          <AddButton pageNavigate={navigateToAddArea} />
        )}
      </div>

      {/* Table */}
      <MUIDataTable title="場域列表" data={records} columns={columns} options={options} />
    </>
  )
}

export default Area
