function SearchButton({ handleClick }) {
  return (
    <button
      onClick={handleClick}
      className="bg-gray-400 hover:bg-gray-300 text-white font-bold py-1 px-4 borde rounded flex align-center justify-center h-8 my-auto"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w- mr-1"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clipRule="evenodd"
        />
      </svg>
      搜尋資料
    </button>
  )
}

export default SearchButton
