import { configureStore } from "@reduxjs/toolkit"
import authReducer from "../features/auth/authSlice"
import permissionReducer from "../features/permission/permissionSlice"
import ownPermissionReducer from "../features/permission/ownPermissionSlice"
import roleSlice from "../features/role/roleSlice"

const store = configureStore({
  reducer: {
    auth: authReducer,
    permission: permissionReducer,
    ownPermission: ownPermissionReducer,
    role: roleSlice,
  },
})

export default store
