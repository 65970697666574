import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";
import moment from 'moment'
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";

const dateFormat = 'YYYY-MM-DD'

export function DatePicker({ control, name, label }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
                name={name}
                control={control}
                defaultValue={moment().format(dateFormat)}
                render={({
                    field: { onChange, value },
                    fieldState: { error }
                }) => (
                    <DesktopDatePicker
                        value={value}
                        label={label}
                        inputFormat="yyyy-MM-dd"
                        onChange={(value) =>
                            onChange(moment(value).format(dateFormat))
                        }
                        renderInput={(params) => <TextField {...params} />}
                    />
                )}
            />
        </LocalizationProvider>
    )
}