import BreadCrumb from "components/BreadCrumb"
import Typography from "@mui/material/Typography"
import { createEvent } from "features/utils/event";
import { Link, useNavigate } from "react-router-dom";
import { EventForm } from './EventForm';

const defaultValues = {
    "immediate": true,
    "time": "10/12/2022 04:20 PM",
    "message": "",
    "device_id": 0,
    "state": 1
}

function CreateEvent() {

    const navigate = useNavigate();
    const breadcrumbs = [
        <Typography key="1" color="text.primary">
            系統設定
        </Typography>,
        <Typography key="2" color="text.secondary">
            <Link to="/setting/event">警示設定</Link>
        </Typography>,
        <Typography key="3" color="text.secondary">
            新增警示
        </Typography>,
    ]

    const handleOnSubmit = data => {
        createEvent({
            "immediate": data.immediate === '1',
            "time": data.time,
            "message": data.message,
            "device_id": data.device_id,
            "state": data.state
        }).then(resp => {
            const id = resp.data?.id
            navigate(`/setting/event/${id}/edit`)
        })
    }

    return (
        <>
            <h1 className="text-2xl font-bold my-3">新增警示</h1>
            <BreadCrumb data={breadcrumbs} />
            <EventForm
                onSubmit={handleOnSubmit}
                defaultValues={defaultValues}
            />
        </>

    )
}

export default CreateEvent