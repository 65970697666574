import { Controller } from "react-hook-form";
import { Radio, FormControlLabel } from '@mui/material';
import { useState, useEffect } from "react";
import { RedioTextField } from "./RedioTextField";

function FishTypeRadio({ control, setValue, getValues, radioValue, label, name }) {
    const [rValue, setRValue] = useState('0')
    useEffect(() => {
        setRValue(radioValue.toString())
    }, [radioValue])

    return (
        <Controller
            render={({ field: { value, onChange } }) => {
                const checked = value === rValue || value.toString() === rValue
                return (<FormControlLabel
                    control={
                        <Radio
                            checked={checked}
                            onClick={(e) => {
                                if (e.target.checked) {
                                    onChange(e.target.value)
                                }
                            }}
                            value={rValue}
                            color="primary"
                            label="other"
                        />
                    }
                    label={
                        <RedioTextField
                            checked={checked}
                            label={label}
                            control={control}
                            name={name}
                        />
                    }
                />)
            }}
            name="fish_type"
            control={control}
        />
    )
}

export default FishTypeRadio