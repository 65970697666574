import { deleteAIOT, getAIOT, setAIOT } from "features/utils/aiot"
import { getDevices } from "features/utils/device"
import { nanoid } from "nanoid"
import { useEffect, useState, useCallback } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"

export function AIOT({ areaId }) {
    const [devices, setDevices] = useState([])
    const defaultValues = {
        device_ids: [],
        area_id: `${areaId}`
    }
    const { register, setValue, handleSubmit, reset } = useForm({
        defaultValues
    })

    const handleFetchDevices = useCallback((id) => {
        getDevices({ area_id: id }).then((resp) => {
            setDevices(resp.data)
        }).catch(() => {
            toast.error('無法抓取感測器')
        })
    }, [])

    const handleFetchAIOT = useCallback((id) => {
        getAIOT(id).then(resp => {
            const { data } = resp
            const deviceIds = data.map(v => `${v.device_id}`)
            setValue('device_ids', deviceIds)
        }).catch(() => toast.error('無法抓取AIOT設定'))
    }, [])

    const handleOnSubmit = (data) => {
        setAIOT(Number(data.area_id), data.device_ids)
            .then(resp => {
                toast.success('成功設定AIOT')
            }).catch(err => {
                toast.error('設定AIOT失敗')
            }).finally(() => {
                handleFetchAIOT(areaId)
            })
    }

    const handleDeleteAIOT = () => {
        deleteAIOT(areaId).then(() => {
            handleFetchAIOT(areaId)
            toast.success('重設成功')
        }).catch(() => {
            toast.error('重設失敗')
        }).finally(() => {
            reset(defaultValues)
        })
    }

    useEffect(() => {
        handleFetchDevices(areaId)
        handleFetchAIOT(areaId)
    }, [handleFetchDevices, areaId])

    return (
        <div>
            <fieldset>
                {
                    devices.map(v =>
                        <div className="flex items-center mb-4" key={nanoid()}>
                            <input type="checkbox" name="device_ids" value={v.id} className="w-4 h-4" {...register('device_ids')} />
                            <label className="block ml-2 text-sm font-medium ">
                                {v.name}
                            </label>
                        </div>
                    )
                }
            </fieldset>
            <div className="mb-6 flex gap-4">
                <button
                    onClick={handleSubmit(handleOnSubmit)}
                    type="submit"
                    className="px-4 py-2 tracking-wide w-30
                    text-white transition-colors duration-200 transform bg-primary-600 rounded-md hover:bg-primary-300 focus:outline-none focus:bg-primary-300 mt-12"
                >
                    送出
                </button>
                <button
                    onClick={handleDeleteAIOT}
                    type="submit"
                    className="px-4 py-2 tracking-wide w-30
                    text-white transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-300 focus:outline-none focus:bg-blue-300 mt-12"
                >
                    重設
                </button>
            </div>
        </div>
    )
}