import { newHttpInstance } from "features/utils/common/http"

const API_URL = "/api/v1/"

// Get Area
export async function getEvents(data = {}) {
    try {
        const searchParams = new URLSearchParams(data);
        let queryParams = '';
        if (searchParams !== '') {
            queryParams = `?${searchParams}`
        }
        const instance = newHttpInstance("GET", `${API_URL}events${queryParams}`)
        const res = await instance.request()
        return res
    } catch (error) {
        throw new Error(error)
    }
}