import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Typography from "@mui/material/Typography"
import useUserLog from "hooks/useUserLog"
import moment from "moment"
import MUIDataTable from "mui-datatables"
import { useEffect, useState } from "react"

function UsageRecord() {
  const [logs] = useUserLog()
  const columns = ["帳號", "API", "Method", "Status", "時間"]
  const [data, setData] = useState([])

  const createData = (row) => {
    let status = "成功"
    if (row.status > 299 || row.status < 200) {
      status = <div className="text-red-600">失敗</div>
    }
    return [
      row.username,
      row.api,
      row.method,
      status,
      moment(row.created_at).format("YYYY-MM-DD hh:mm:ss"),
    ]
  }

  useEffect(() => {
    if (logs == null) {
      return
    }
    const newData = logs.map((v) => createData(v))
    setData(newData)
  }, [logs])

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      系統設定
    </Typography>,
    <Typography key="2" color="text.secondary">
      帳號與動作 log
    </Typography>,
  ]

  const options = {
    responsive: "standard",
    filterType: "checkbox",
    selectableRows: false,
    print: false,
    download: false,
  }
  return (
    <>
      <h1 className="my-3 text-2xl font-bold">帳號與動作 log</h1>
      {/* BreadCrumb */}
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="py-2"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <MUIDataTable title="帳號與動作 log" data={data} columns={columns} options={options} />
    </>
  )
}

export default UsageRecord
