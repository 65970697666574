import axios from "axios"
import { getProfile } from "features/utils"

const API_URL = "/api/v1/auth/"

// Login user
const login = async (userData) => {
  const loginResponse = await axios.post(`${API_URL}login`, userData)
  await setProfile();
  return loginResponse.data
}

// Logout user
const logout = () => localStorage.removeItem("user")

const setProfile = async () => {
  const profileResponse = await getProfile()
  localStorage.setItem("user", JSON.stringify(profileResponse.data))
}

const authService = {
  logout,
  login,
  setProfile,
}

export default authService
