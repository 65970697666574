import { Event } from "components/real-time/Event"
import { getEvents } from "features/utils/events"
import { useEffect, useCallback, useState } from "react"
import { toast } from "react-toastify"
import { nanoid } from "nanoid"
import { useForm } from "react-hook-form"
import { getAreas } from "features/utils/area"
import ChartButton from "components/button/ChartButton"
import MUIDataTable from "mui-datatables"
import { getDevices } from "features/utils/device"
import moment from "moment"
import { RealTimeSearchForm } from "../RealTimeSearchForm"

const columns = ["ID", "感測器", "場域名稱", "創建時間", "最後一筆新增資料的時間", "操作"]

const options = {
  responsive: "standard",
  filterType: "checkbox",
  print: false,
  download: false,
  selectableRows: false,
}

function Table({searchAreas}) {
  const [areas, setAreas] = useState([])
  const [devices, setDevices] = useState([])
  const [selectAreaId, setSelectAreaId] = useState(0)
  const [eventData, setEventData] = useState([])
  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      area_id: 0,
      device_id: 0,
    },
  })


  // get the devices
  const fetchAreas = useCallback(() => {
    getAreas({
      only: searchAreas
    })
      .then((resp) => setAreas(resp.data))
      .catch(() => toast.error("抓取場域資料錯誤"))
  }, [])

  const handleSelectArea = (e) => {
    const { value } = e.target
    if (value === undefined || value === null) {
      return
    }
    setSelectAreaId(value)
  }

  useEffect(() => {
    fetchAreas()
  }, [fetchAreas])

  // get the devices
  const fetchDevice = useCallback((id = null) => {
    let params = {
      area_ids: searchAreas
    }
    if (id !== null) {
      params = {
        area_id: id,
      }
    }
    getDevices(params)
      .then((resp) => setDevices(resp.data))
      .catch(() => toast.error("抓取感測器資料錯誤"))
  }, [])
  useEffect(() => {
    if (selectAreaId === 0 || selectAreaId === "0") {
      fetchDevice()
      setDevices([])
      return
    }
    fetchDevice(selectAreaId)
  }, [fetchDevice, selectAreaId])

  // get the events
  const handleFetchEvents = useCallback((data = {}) => {
    getEvents(data)
      .then((resp) => {
        setEventData(resp.data)
      })
      .catch(() => {
        toast.error("無法取得 events")
      })
  }, [])

  const onSubmit = (data) => {
    handleFetchEvents({
      device_id: data.device_id,
      end_time: data.end_time,
      begin_time: data.start_time,
    })
  }

  useEffect(() => {
    handleFetchEvents()
  }, [handleFetchEvents])

  /* MUI Table */
  const deviceData = devices?.map((v) => 
    /* Return Table */
     [
      v?.id,
      v?.vendor_info?.label,
      v?.name,
      moment(v?.created_at).format("YYYY-MM-DD hh:mm:ss"),
      moment(v?.latest_at).format("YYYY-MM-DD hh:mm:ss"),
      <div className="flex gap-4">
        <ChartButton link={`${selectAreaId}/edit/device/${v?.id}/chart`} content="圖表" />
      </div>,
    ]
  )

  return (
    <>
      <RealTimeSearchForm
        register={register}
        control={control}
        handleSubmit={handleSubmit(onSubmit)}
        areas={areas}
        selectAreaId={selectAreaId}
        devices={devices}
        handleSelectArea={handleSelectArea}
      />
      {selectAreaId !== 0 && (
        <div className="px-4 py-5 mt-4 mb-6 overflow-hidden overflow-x-auto rounded-lg bg-slate-50 drop-shadow-lg whitespace-nowrap">
          {eventData.map((v) => (
            <Event key={nanoid()} data={v} />
          ))}
        </div>
      )}

      <MUIDataTable title="裝置列表" data={deviceData} columns={columns} options={options} />
    </>
  )
}

export default Table
