import React, { useState, useEffect, useRef } from "react"
import { useQuery } from "react-query"
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Spinner from "components/Spinner"
import { toast } from "react-toastify"
import { logout, reset } from "features/auth/authSlice"
import { getProfile } from "features/utils"
import {
  addOwnPermissionList,
  initialOwnPermissionList,
  addAreasList,
} from "features/permission/ownPermissionSlice"
import SidebarLinkGroup from "./SidebarLinkGroup"

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation()
  const dispatch = useDispatch()
  const { ownPermissionList } = useSelector((state) => state.ownPermission)
  const { pathname } = location

  const trigger = useRef(null)
  const sidebar = useRef(null)

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded")
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  )

  const navigate = useNavigate()
  const onLogout = () => {
    dispatch(initialOwnPermissionList())
    dispatch(logout())
    dispatch(reset())
    navigate("/")
  }

  const [ownPermission, setOwnPermission] = useState([])
  const { isFetching, data, error, isError, isSuccess } = useQuery("profile", getProfile)

  if (isError) {
    toast.error(error.message)
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(addOwnPermissionList(data?.data?.permissions))
      dispatch(addAreasList(data?.data?.areas))
      setOwnPermission(data?.data?.permissions)
    }
  }, [isFetching])

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded)
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded")
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded")
    }
  }, [sidebarExpanded])

  const envValue = `${process.env.REACT_APP_TITLE}`
  const envWord = "智慧養殖跨場域資料庫分析平台"

  const colorCtl = {
    primary_color: envValue === envWord ? "bg-slate-900" : "bg-teal-900",
    font_color: envValue === envWord ? "text-primary-600" : "text-green-300",
    sidebar_bgc: envValue === envWord ? "bg-slate-800" : "bg-teal-800",
    icon_default: envValue === envWord ? "text-slate-600" : "text-slate-300",
  }

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 ${
          colorCtl.primary_color
        } bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      />

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 ${
          colorCtl.sidebar_bgc
        } p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <h3 className="pl-3 text-xs font-semibold uppercase text-slate-500">
              <span
                className="hidden w-6 text-center lg:block lg:sidebar-expanded:hidden 2xl:hidden"
                aria-hidden="true"
              >
                •••
              </span>
              <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">Pages</span>
            </h3>
            <ul className="mt-3">
              {/* 即時資料顯示 */}

              {ownPermission.includes("REAL_TIME_READ") && (
                <li
                  className={`sidebar-title-bg ${
                    pathname === "/realtime" && `${colorCtl.primary_color}`
                  }`}
                >
                  <NavLink end to="/realtime" className="sidebar-title-font">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          className={`fill-current ${colorCtl.icon_default} ${
                            pathname === "/realtime" && `${colorCtl.font_color}`
                          }`}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                        />
                      </svg>
                      <span className="ml-3 font-medium duration-200 text-md lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                        即時資料顯示
                      </span>
                    </div>
                  </NavLink>
                </li>
              )}

              {/* 警示閥值設定 */}
              {ownPermission.includes("METERINDCO_READ") && (
                <li
                  className={`sidebar-title-bg ${
                    pathname === "/meterindco" && `${colorCtl.primary_color}`
                  }`}
                >
                  <NavLink end to="/meterindco" className="sidebar-title-font">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          className={`fill-current ${colorCtl.icon_default} ${
                            pathname === "/meterindco" && `${colorCtl.font_color}`
                          }`}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                        />
                      </svg>
                      <span className="ml-3 font-medium duration-200 text-md lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                        固定式監控影像
                      </span>
                    </div>
                  </NavLink>
                </li>
              )}

              {/* AI 預測模型 */}
              {(ownPermission.includes("PREDICT_DEFAULT_READ") ||
                ownPermission.includes("PREDICT_TRAIN_READ")) && (
                <SidebarLinkGroup activecondition={pathname.includes("ecommerce")}>
                  {(handleClick, open) => (
                    <>
                      <a
                        href="#0"
                        className="sidebar-title-font"
                        onClick={(e) => {
                          e.preventDefault()
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true)
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 shrink-0"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                className={`${colorCtl.icon_default}`}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                              />
                            </svg>

                            <span className="ml-3 font-medium duration-200 text-md lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                              AI 預測模型
                            </span>
                          </div>
                          {/* Icon */}
                          <div className="flex ml-2 shrink-0">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                open && "transform rotate-180"
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-9 mt-3 ${!open && "hidden"}`}>
                          {ownPermission.includes("PREDICT_DEFAULT_READ") && (
                            <li
                              className={`sidebar-group-space ${
                                pathname === "/predict/predict-model" && `${colorCtl.primary_color}`
                              }`}
                            >
                              <NavLink
                                end
                                to="/predict/predict-model"
                                className="sidebar-group-nav"
                              >
                                <div className="flex items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 mr-2"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                                      clipRule="evenodd"
                                      className={`${
                                        pathname === "/predict/predict-model" &&
                                        `${colorCtl.font_color}`
                                      }`}
                                    />
                                  </svg>

                                  <span className="sidebar-group-primary">預測模式</span>
                                </div>
                              </NavLink>
                            </li>
                          )}

                          {ownPermission.includes("PREDICT_TRAIN_READ") && (
                            <li
                              className={`sidebar-group-space ${
                                pathname === "/predict/train-model" && `${colorCtl.primary_color}`
                              }`}
                            >
                              <NavLink end to="/predict/train-model" className="sidebar-group-nav">
                                <div className="flex items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-5 h-5 mr-2"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 01-.293.707l-4 4C.817 14.769 2.156 18 4.828 18h10.343c2.673 0 4.012-3.231 2.122-5.121l-4-4A1 1 0 0113 8.172V4.414l.707-.707A1 1 0 0013 2H7zm2 6.172V4h2v4.172a3 3 0 00.879 2.12l1.027 1.028a4 4 0 00-2.171.102l-.47.156a4 4 0 01-2.53 0l-.563-.187a1.993 1.993 0 00-.114-.035l1.063-1.063A3 3 0 009 8.172z"
                                      clipRule="evenodd"
                                      className={`${
                                        pathname === "/predict/train-model" &&
                                        `${colorCtl.font_color}`
                                      }`}
                                    />
                                  </svg>

                                  <span className="sidebar-group-primary">訓練模式</span>
                                </div>
                              </NavLink>
                            </li>
                          )}
                        </ul>
                      </div>
                    </>
                  )}
                </SidebarLinkGroup>
              )}

              {/* 歷史資料總覽 */}
              {ownPermission.includes("HISTORY_READ") && (
                <SidebarLinkGroup activecondition={pathname.includes("ecommerce")}>
                  {(handleClick, open) => (
                    <>
                      <a
                        href="#0"
                        className="sidebar-title-font"
                        onClick={(e) => {
                          e.preventDefault()
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true)
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 shrink-0"
                              viewBox="0 0 20 20"
                              fill="none"
                              stroke="currentColor"
                            >
                              <path
                                className={`fill-current ${colorCtl.icon_default}`}
                                d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z"
                              />
                            </svg>
                            <span className="ml-3 font-medium duration-200 text-md lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                              歷史資料總覽
                            </span>
                          </div>
                          {/* Icon */}
                          <div className="flex ml-2 shrink-0">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                open && "transform rotate-180"
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-9 mt-3 ${!open && "hidden"}`}>
                          <li
                            className={`sidebar-group-space ${
                              pathname === "/history/general" && `${colorCtl.primary_color}`
                            }`}
                          >
                            <NavLink end to="/history/general" className="sidebar-group-nav">
                              <div className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5 mr-2"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                    className={`${
                                      pathname === "/history/general" && `${colorCtl.font_color}`
                                    }`}
                                  />
                                </svg>
                                <span className="sidebar-group-primary">一般查詢</span>
                              </div>
                            </NavLink>
                          </li>
                          <li
                            className={`sidebar-group-space ${
                              pathname === "/history/custom" && `${colorCtl.primary_color}`
                            }`}
                          >
                            <NavLink end to="/history/custom" className="sidebar-group-nav">
                              <div className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={2}
                                  stroke="currentColor"
                                  className="w-5 h-5 mr-2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                    className={`${
                                      pathname === "/history/custom" && `${colorCtl.font_color}`
                                    }`}
                                  />
                                </svg>

                                <span className="sidebar-group-primary">自訂查詢</span>
                              </div>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                </SidebarLinkGroup>
              )}

              {/* 系統設定 */}
              <SidebarLinkGroup activecondition={pathname.includes("team")}>
                {(handleClick, open) => (
                  <>
                    <a
                      href="#0"
                      className="sidebar-title-font"
                      onClick={(e) => {
                        e.preventDefault()
                        sidebarExpanded ? handleClick() : setSidebarExpanded(true)
                      }}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 shrink-0"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              className={`fill-current ${colorCtl.icon_default}`}
                              fillRule="evenodd"
                              d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className="ml-3 font-medium duration-200 text-md lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                            系統設定
                          </span>
                        </div>
                        {/* Icon */}
                        <div className="flex ml-2 shrink-0">
                          <svg
                            className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                              open && "transform rotate-180"
                            }`}
                            viewBox="0 0 12 12"
                          >
                            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                          </svg>
                        </div>
                      </div>
                    </a>
                    <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                      <ul className={`pl-9 mt-3 ${!open && "hidden"}`}>
                        {/* 查看警示設定 */}
                        {ownPermission.includes("SETTING_EVENT_READ") && (
                          <li
                            className={`sidebar-group-space ${
                              pathname === "/setting/event" && `${colorCtl.primary_color}`
                            }`}
                          >
                            <NavLink end to="/setting/event" className="sidebar-group-nav">
                              <div className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5 mr-2"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    className={`${
                                      pathname === "/setting/event" && `${colorCtl.font_color}`
                                    }`}
                                  />
                                </svg>
                                <span className="sidebar-group-primary">警示設定</span>
                              </div>
                            </NavLink>
                          </li>
                        )}
                        {/* 查看場域資料 */}
                        {ownPermission.includes("AREA_READ") && (
                          <li
                            className={`sidebar-group-space ${
                              pathname === "/setting/area" && `${colorCtl.primary_color}`
                            }`}
                          >
                            <NavLink end to="/setting/area" className="sidebar-group-nav">
                              <div className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5 mr-2"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                                    clipRule="evenodd"
                                    className={`${
                                      pathname === "/setting/area" && `${colorCtl.font_color}`
                                    }`}
                                  />
                                </svg>
                                <span className="sidebar-group-primary">場域管理</span>
                              </div>
                            </NavLink>
                          </li>
                        )}
                        {/* 廠商管理 */}
                        {ownPermission.includes("VENDOR_SETTING") && (
                          <li
                            className={`sidebar-group-space ${
                              pathname === "/setting/vendor" && `${colorCtl.primary_color}`
                            }`}
                          >
                            <NavLink end to="/setting/vendor" className="sidebar-group-nav">
                              <div className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5 mr-2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                                    className={`${
                                      pathname === "/setting/vendor" && `${colorCtl.font_color}`
                                    }`}
                                  />
                                </svg>

                                <span className="sidebar-group-primary">廠商管理</span>
                              </div>
                            </NavLink>
                          </li>
                        )}
                        {ownPermission.includes("INSTRUMENT_READ") && (
                          <li
                            className={`sidebar-group-space ${
                              pathname === "/setting/area" && `${colorCtl.primary_color}`
                            }`}
                          >
                            <NavLink end to="/setting/instrument" className="sidebar-group-nav">
                              <div className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5 mr-2"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                                    clipRule="evenodd"
                                    className={`${
                                      pathname === "/setting/instrument" && `${colorCtl.font_color}`
                                    }`}
                                  />
                                </svg>
                                <span className="sidebar-group-primary">儀器管理</span>
                              </div>
                            </NavLink>
                          </li>
                        )}
                        {/* 查看資料表用量 */}
                        {ownPermission.includes("DATABASE_USAGE_READ") && (
                          <li
                            className={`sidebar-group-space ${
                              pathname === "/setting/dosage" && `${colorCtl.primary_color}`
                            }`}
                          >
                            <NavLink end to="/setting/dosage" className="sidebar-group-nav">
                              <div className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5 mr-2"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
                                    className={`${
                                      pathname === "/setting/dosage" && `${colorCtl.font_color}`
                                    }`}
                                  />
                                </svg>
                                <span className="sidebar-group-primary">資料表用量</span>
                              </div>
                            </NavLink>
                          </li>
                        )}
                        {/* 帳號與動作 log */}
                        {ownPermission.includes("APP_RECORD_READ") && (
                          <li
                            className={`sidebar-group-space ${
                              pathname === "/setting/usage-record" && `${colorCtl.primary_color}`
                            }`}
                          >
                            <NavLink end to="/setting/usage-record" className="sidebar-group-nav">
                              <div className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5 mr-2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                                    className={`${
                                      pathname === "/setting/usage-record" &&
                                      `${colorCtl.font_color}`
                                    }`}
                                  />
                                </svg>
                                <span className="sidebar-group-primary">帳號與動作 log</span>
                              </div>
                            </NavLink>
                          </li>
                        )}
                        {ownPermission.includes("ROLE_READ") && (
                          <li
                            className={`sidebar-group-space ${
                              pathname === "/setting/group" && `${colorCtl.primary_color}`
                            }`}
                          >
                            <NavLink end to="/setting/group" className="sidebar-group-nav">
                              <div className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5 mr-2"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                                    className={`${
                                      pathname === "/setting/group" && `${colorCtl.font_color}`
                                    }`}
                                  />
                                </svg>
                                <span className="sidebar-group-primary">權限管理</span>
                              </div>
                            </NavLink>
                          </li>
                        )}
                        {/* 逾時設定 */}
                        {ownPermission.includes("TOKEN_READ") && (
                          <li
                            className={`sidebar-group-space ${
                              pathname === "/setting/token-setting" && `${colorCtl.primary_color}`
                            }`}
                          >
                            <NavLink end to="/setting/token-setting" className="sidebar-group-nav">
                              <div className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={2}
                                  stroke="currentColor"
                                  className="w-5 h-5 mr-2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                                    className={`${
                                      pathname === "/setting/token-setting" &&
                                      `${colorCtl.font_color}`
                                    }`}
                                  />
                                </svg>

                                <span className="sidebar-group-primary">逾時設定</span>
                              </div>
                            </NavLink>
                          </li>
                        )}
                        {ownPermission.includes("PERMISSION_READ") && (
                          <li
                            className={`sidebar-group-space ${
                              pathname === "/setting/regulation" && `${colorCtl.primary_color}`
                            }`}
                          >
                            <NavLink end to="/setting/regulation" className="sidebar-group-nav">
                              <div className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5 mr-2"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                                    className={`${
                                      pathname === "/setting/regulation" && `${colorCtl.font_color}`
                                    }`}
                                  />
                                </svg>
                                <span className="sidebar-group-primary">查看權限</span>
                              </div>
                            </NavLink>
                          </li>
                        )}
                        {ownPermission.includes("ADMIN_READ") && (
                          <li
                            className={`sidebar-group-space ${
                              pathname === "/setting/manager" && `${colorCtl.primary_color}`
                            }`}
                          >
                            <NavLink end to="/setting/manager" className="sidebar-group-nav">
                              <div className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5 mr-2"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                    clipRule="evenodd"
                                    className={`${
                                      pathname === "/setting/manager" && `${colorCtl.font_color}`
                                    }`}
                                  />
                                </svg>
                                <span className="sidebar-group-primary">登入帳密管理</span>
                              </div>
                            </NavLink>
                          </li>
                        )}

                        {ownPermission.includes("SWAGGER_READ") && (
                          <li
                            className={`sidebar-group-space ${
                              pathname === "" && `${colorCtl.primary_color}`
                            }`}
                          >
                            <div
                              className="sidebar-group-nav"
                              onClick={() => {
                                window.open("/api/documentation", "_blank")
                              }}
                            >
                              <div className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5 mr-2"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                                    className={`${pathname === "" && `${colorCtl.font_color}`}`}
                                  />
                                </svg>
                                <span className="sidebar-group-primary">API 說明文件</span>
                              </div>
                            </div>
                          </li>
                        )}

                        {ownPermission.includes("LOG_READ") && (
                          <li
                            className={`sidebar-group-space ${
                              pathname === "/setting/log-viewer" && `${colorCtl.primary_color}`
                            }`}
                          >
                            <div
                              className="sidebar-group-nav"
                              onClick={() => {
                                window.open("/api/v1/logs", "_blank")
                              }}
                            >
                              <div className="flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5 mr-2"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                                    className={`${
                                      pathname === "/setting/log-viewer" && `${colorCtl.font_color}`
                                    }`}
                                  />
                                </svg>
                                <span className="sidebar-group-primary">查看 Log</span>
                              </div>
                            </div>
                          </li>
                        )}

                        <li
                          className={`sidebar-group-space ${
                            pathname === "" && `${colorCtl.primary_color}`
                          }`}
                        >
                          <NavLink end to="/" className="sidebar-group-nav" onClick={onLogout}>
                            <div className="flex items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5 mr-2"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                />
                              </svg>
                              <span className="sidebar-group-primary">登出</span>
                            </div>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </SidebarLinkGroup>
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="justify-end hidden pt-3 mt-auto lg:inline-flex 2xl:hidden">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className={`${colorCtl.icon_default}`} d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
