export const transferWords = (permission) => permissions[permission] || ""
const permissions = {
  REAL_TIME_READ: "即時資料顯示",
  THRESHOLD_CREATE: "新增警示設定",
  THRESHOLD_READ: "警示設定",
  THRESHOLD_UPDATE: "更新警示設定",
  THRESHOLD_DELETE: "刪除警示設定",
  PREDICT_DEFAULT_READ: "查看預測模式",
  PREDICT_TRAIN_READ: "查看訓練模式",
  HISTORY_READ: "查看水質資訊",
  SETTING_EVENT_CREATE: "新增事件設定",
  SETTING_EVENT_READ: "查看事件設定",
  SETTING_EVENT_UPDATE: "更新事件設定",
  SETTING_EVENT_DELETE: "刪除事件設定",
  AREA_CREATE: "新增場域資料",
  AREA_READ: "查看場域資料",
  AREA_UPDATE: "更新場域資料",
  AREA_DELETE: "刪除場域資料",
  INSTRUMENT_READ: "查看儀器資料",
  DATABASE_USAGE_READ: "查看資料表用量",
  ROLE_CREATE: "新增角色資料",
  ROLE_READ: "查看角色資料",
  ROLE_UPDATE: "更新角色資料",
  ROLE_DELETE: "刪除角色資料",
  ROLE_HAS_PERMISSION_CREATE: "新增角色可使用的權限資料",
  ROLE_HAS_PERMISSION_UPDATE: "更新角色可使用的權限資料",
  ROLE_HAS_PERMISSION_DELETE: "刪除角色可使用的權限資料",
  ADMIN_HAS_ROLE_CREATE: "新增使用者角色身份",
  ADMIN_HAS_ROLE_UPDATE: "更新使用者角色身份",
  ADMIN_HAS_ROLE_DELETE: "刪除使用者角色身份",
  PERMISSION_READ: "查看權限",
  ADMIN_CREATE: "新增登入帳密管理",
  ADMIN_DELETE: "刪除登入帳密管理",
  ADMIN_UPDATE: "更新登入帳密管理",
  ADMIN_READ: "查看登入帳密管理",
  SWAGGER_READ: "查看API說明文件",
  LOG_READ: "查看Log",
  APP_RECORD_READ: "帳號與動作 log",
  TOKEN_READ: "Token設定",
  METERINDCO_READ: "固定式監控影像",
  VENDOR_SETTING: "廠商設定",
  ROLE_ASSIGN_AREA: "新增角色可使用場域",
  ROLE_AREA_READ: "查看角色可使用場域",
}
