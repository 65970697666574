import Spinner from "components/Spinner"
import { getChartTable } from "features/utils"
import { useState, useEffect, useCallback } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import MUIDataTable from "mui-datatables"
import TextField from "@mui/material/TextField"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import SearchButton from "components/button/SearchButton"
import parse from 'html-react-parser';

const dateTranslate = (originTime) => {
  const year = originTime.getFullYear()
  const month = String(originTime.getMonth() + 1).padStart(2, "0")
  const day = String(originTime.getDate()).padStart(2, "0")
  return `${year}-${month}-${day}`
}

function ChartTable() {
  const params = useParams()
  const [columnTitle, setColumnTitle] = useState([])
  const [columnContent, setColumnContent] = useState([])
  const [isLoading, setIsLogin] = useState(false)
  const [startTime, setStartTime] = useState(dateTranslate(new Date))
  const [endTime, setEndTime] = useState(dateTranslate(new Date))
  const { deviceId } = params

  const handleFetchChartTableData = useCallback(() => {
    setIsLogin(true)
    getChartTable(deviceId, startTime, endTime).then(resp => {
      const { data } = resp
      setColumnTitle(data?.columns)
      setColumnContent(data?.data)
    }).catch(() => toast.error('抓取圖表資料錯誤'))
      .finally(() => setIsLogin(false))
  }, [deviceId, startTime, endTime])

  const startHandleChange = (newValue) => {
    setStartTime(dateTranslate(newValue))
  }

  const endHandleChange = (newValue) => {
    setEndTime(dateTranslate(newValue))
  }

  const handleClick = () => {
    handleFetchChartTableData()
  }

  if (isLoading) {
    return <Spinner />
  }

  const getField = columnTitle?.map((i) => {
    const eachField = i?.field
    return eachField
  })

  const options = {
    responsive: "standard",
    print: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: "none",
  }

  const columnsData = columnContent?.map((i) => {
    const newArr = []
    getField?.forEach((j) => newArr.push(
      parse(`${i?.[j]}`)
    ))
    return newArr
  })

  const columns = columnTitle?.map((i) => {
    let title = i.label
    if (i.unit !== "") {
      title = `${title}(${i.unit})`
    }
    return {
      name: i.field,
      label: title,
      options: {
        customBodyRender: (v) => v,
      },
    }
  })

  return (
    <div>
      {
        isLoading ? <Spinner /> :
          <div>
            <div className="flex justify-between mb-4">
              <div className="space-x-10">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="開始時間"
                    inputFormat="yyyy-MM-dd"
                    value={startTime}
                    onChange={startHandleChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="結束時間"
                    inputFormat="yyyy-MM-dd"
                    value={endTime}
                    onChange={endHandleChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <SearchButton handleClick={handleClick} />
            </div>
            <MUIDataTable title="資料列表" data={columnsData} columns={columns} options={options} />
          </div>
      }
    </div>
  )
}

export default ChartTable
