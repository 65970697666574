import { newHttpInstance } from "features/utils/common/http"

const API_URL = "/api/v1/"

// Get Area
export async function getVendorMetrics(name) {
    try {
        const instance = newHttpInstance("GET", `${API_URL}vendor-metrics/${name}`)
        const res = await instance.request()
        return res
    } catch (error) {
        throw new Error(error)
    }
}

export async function getVendorAreas(name) {
    try {
        const instance = newHttpInstance("GET", `${API_URL}vendor-areas/${name}`)
        const res = await instance.request()
        return res
    } catch (error) {
        throw new Error(error)
    }
}

