import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import Tabs from "@mui/material/Tabs"
import TabPanel from "@mui/lab/TabPanel"
import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"
import { useMutation } from "react-query"
import { postArea } from "features/utils"
import { toast } from "react-toastify"
import Spinner from "components/Spinner"
import CustomDialog from "components/dialog/CustomDialog"
import BreadCrumb from "components/BreadCrumb"
import AreaForm from "./Form/AreaForm"

const defaultValues = {
  "name": "",
  "description": "",
  "owner": "",
  "club": "",
  "fish_type": 0,
  "fish_special": "",
  "fish_mix": "",
  "stage": 0,
  "other_stage": "",
  "fishpond": 0,
  "other_fishpond": "",
  "structure": 0,
  "area": "",
  "water_volume": "",
  "depth": "",
  "bred_amount": "",
  "capacity": "",
  'city': '',
  'district': '',
  'address': '',
  "latitude": 0,
  "longitude": 0,
}

function CreateArea() {
  const navigate = useNavigate()
  const [value, setValue] = useState("1")
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(defaultValues)
  const { isLoading, isError, error, mutate } = useMutation(postArea, {
    onSuccess: resp => {
      toast.success("新增成功")
      navigate(`/setting/area/${resp?.data.id}/edit`)
    },
    onError: (err) => {
      toast.error(error.message)
    }
  })

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const onAddSubmit = (data) => {
    if (!open) {
      setOpen(true)
      setData(data)
      return
    }

    mutate(data)
    setOpen(false)
  }

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      系統設定
    </Typography>,
    <Typography key="2" color="text.primary">
      <Link to="/setting/area">設定場域</Link>
    </Typography>,
    <Typography key="3" color="text.secondary">
      新增場域
    </Typography>,
  ]

  return (
    <>
      {
        isLoading ? <Spinner /> :
          <>
            <h1 className="text-2xl font-bold my-3">新增場域</h1>
            <BreadCrumb data={breadcrumbs} />
            <Box sx={{ width: "100%", typography: "body1" }} className="my-3">
              <TabContext value={value}>
                <Tabs
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                  value={value}
                  onChange={handleChange}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="secondary tabs example"
                >
                  <Tab label="Info" value="1" />
                  <Tab label="Device" value="2" disabled />
                  <Tab label="AIOT" value="3" disabled />
                </Tabs>
                <TabPanel value="1" className="">
                  <AreaForm values={defaultValues} onSubmit={onAddSubmit} />
                </TabPanel>
                <TabPanel value="2">裝置</TabPanel>
              </TabContext>
            </Box>
          </>
      }
      <CustomDialog handleClose={handleClose} method={() => {
        onAddSubmit(data)
      }} open={open} content="新增" />
    </>
  )
}

export default CreateArea
