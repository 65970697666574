import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  ownPermissionList: [],
  ownAreaList: []
}

export const ownPermissionSlice = createSlice({
  name: "ownPermission",
  initialState,
  reducers: {
    addAreasList: (state, action) => {
      state.ownAreaList = action.payload
    },
    addOwnPermissionList: (state, action) => {
      state.ownPermissionList = action.payload
    },
    initialOwnPermissionList: (state, action) => {
      state.ownPermissionList = []
    },
  },
})

export const { addOwnPermissionList, initialOwnPermissionList, addAreasList } = ownPermissionSlice.actions
export default ownPermissionSlice.reducer
