import { useEffect, useState } from "react"

export function Event({ data }) {
    return (
        <div className="max-w-sm rounded overflow-hidden shadow-lg inline-block mr-4 whitespace-">
            <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">
                    {data.state === 0 && '其他'}
                    {data.state === 1 && '警戒'}
                    {data.state === 2 && '危險'}
                </div>
                <p className="text-gray-700 text-base">
                    {data.message}
                </p>
            </div>
            <div className="px-6">
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    {data?.device?.area?.name}
                </span>
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    {data?.device?.name}
                </span>
            </div>
            <div className="px-6 pt-4 pb-2">
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{data.time}</span>
            </div>
        </div>
    )
}