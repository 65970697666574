import { useMutation, useQuery } from "react-query";
import { TextField } from "@mui/material"
import { Controller, useForm } from "react-hook-form";
import { getPermissions, postRole, putRoles } from "features/utils";
import { getAreas } from "features/utils/area";
import { toast } from "react-toastify";
import SelectOptionV2 from "./SelectOptionV2";

function GroupFormV2({ defaultValues, formStatus, roleId }) {
    const { data: permissionData } = useQuery("permissions", getPermissions)
    const { data: areaData } = useQuery("area", getAreas)
    const { handleSubmit, control, setValue } = useForm({ defaultValues });

    const onSuccess = () => {
        toast.success(formStatus === "create" ? "新增成功" : "修改成功");
        window.location.reload();
    }

    const { mutate: addRole } = useMutation(postRole, { onSuccess })
    const { mutate: updateRole } = useMutation((v) => putRoles(v.data, v.roleId), { onSuccess })

    const findIds = (names) => {
        const foundIds = [];
        names?.forEach((name) => {
            permissionData?.forEach((item) => {
                if (item.name === name) {
                    foundIds.push(item.id);
                }
            });
        });
        return foundIds;
    }

    const onSubmit = (data) => {
        if (formStatus === "create") {
            if (Array.isArray(data?.area_ids)) {
                const isAllUndefined = data.area_ids.every((value) => value === undefined);

                if (isAllUndefined) {
                    data.area_ids = [];
                }
            } else if (data?.area_ids === "") {
                data.area_ids = [];
            }

            addRole(data);
        } else {
            const updatedData = { ...data };
            delete updatedData.areas;
            updateRole({ data: updatedData, roleId });
        }
    }

    return (
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <h1 className="font-medium mb-2">群組</h1>
            <div className="mb-4">
                <Controller
                    render={({ field }) =>
                        <TextField
                            className="w-full"
                            id="outlined-basic"
                            label="群組名稱"
                            variant="outlined"
                            required
                            value={field.value || ''}
                            onChange={field.onChange}
                        />
                    }
                    name="name"
                    control={control}
                />
            </div>

            <Controller
                render={({ field }) =>
                    <TextField
                        className="w-full"
                        id="outlined-basic"
                        label="群組描述"
                        variant="outlined"
                        required
                        value={field.value || ''}
                        onChange={field.onChange}
                    />
                }
                name="description"
                control={control}
            />

            <SelectOptionV2 data={permissionData} setValue={setValue} control={control} Controller={Controller} titleName="群組名稱" defaultValues={findIds(defaultValues.permissions)} formStatus={formStatus} />
            <SelectOptionV2 data={areaData?.data} setValue={setValue} control={control} Controller={Controller} titleName="場域名稱" label="area" defaultValues={defaultValues.areas} formStatus={formStatus} />
            <button
                type="submit"
                className="px-4 py-2 mt-12 tracking-wide text-white transition-colors duration-200 transform rounded-md w-full bg-primary-600 hover:bg-primary-300 focus:outline-none focus:bg-primary-300"
            >
                送出
            </button>
        </form>
    )
}

export default GroupFormV2