import Typography from "@mui/material/Typography"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import TextField from "@mui/material/TextField"
import { Controller, useForm } from "react-hook-form"
import { getJwtTime, putJwtTime } from "features/utils/jwt"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import Spinner from "components/Spinner"

function TokenSetting() {
  const [JwtData, setJwtData] = useState()
  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      系統設定
    </Typography>,
    <Typography key="2" color="text.secondary">
      逾時設定
    </Typography>,
  ]

  const defaultValues = {
    expiry_time: JwtData,
  }
  const { handleSubmit, control } = useForm({ defaultValues })

  const handleGetJwtTime = useCallback(() => {
    getJwtTime()
      .then((resp) => {
        setJwtData(resp.data.expiry_time)
      })
      .catch(() => {
        toast.error("無法取得 JWT Time")
      })
  }, [])

  useEffect(() => {
    handleGetJwtTime()
  }, [handleGetJwtTime])

  const onSubmit = (data) => {
    putJwtTime(data)
      .then((resp) => {
        toast.success("新增成功")
      })
      .catch((e) => {
        toast.error(e.error)
      })
  }

  return (
    <>
      <h1 className="text-2xl font-bold my-3">逾時設定</h1>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="py-2"
      >
        {breadcrumbs}
      </Breadcrumbs>

      <div className="w-80 mx-auto mt-10">
        {JwtData ? (
          <form onSubmit={handleSubmit((data) => onSubmit(data))} className="form">
            <Controller
              name="expiry_time"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  defaultValue={JwtData}
                  className="w-full"
                  label="過期時間"
                  variant="outlined"
                  rules={{ required: true }}
                  {...field}
                />
              )}
            />

            <button
              type="submit"
              className="w-full px-4 py-2 tracking-wide 
                    text-white transition-colors duration-200 transform bg-primary-600 rounded-md hover:bg-primary-300 focus:outline-none focus:bg-primary-300 mt-12"
            >
              送出
            </button>
          </form>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  )
}

export default TokenSetting
