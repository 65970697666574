import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import Typography from "@mui/material/Typography"
import BreadCrumb from "components/BreadCrumb"
import SmallButton from "components/button/predict/SmallButton"
import MUIDataTable from "mui-datatables"
import { useCallback, useState, useEffect } from "react"
import { getEvents } from "features/utils/events"
import AddButton from "components/button/AddButton"
import moment from "moment"

function Event() {
  const { ownPermissionList } = useSelector((state) => state.ownPermission)
  const navigate = useNavigate()
  const navigateToAdd = () => {
    navigate("/setting/event/create")
  }
  const [data, setData] = useState([])
  const fetchData = useCallback(() => {
    getEvents().then((resp) => setData(resp.data))
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      系統設定
    </Typography>,
    <Typography key="2" color="text.secondary">
      警示設定
    </Typography>,
  ]

  const options = {
    responsive: "standard",
    print: false,
    download: false,
    search: true,
    filter: true,
    filterArrayFullMatch: false,
    filterType: "checkbox",
    elevation: 1,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 50],
    selectableRowsHideCheckboxes: false,
  }

  if (!ownPermissionList.includes("SETTING_EVENT_DELETE")) {
    options.customToolbarSelect = () => {}
  }

  const columns = [
    {
      name: "time",
      label: "事件時間",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "message",
      label: "事件訊息",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "device_id",
      label: "事件區域",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "state",
      label: "事件類別",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          switch (value) {
            case 1:
              return "警戒"
            case 2:
              return "危險"
            default:
              return "其他"
          }
        },
      },
    },
    {
      name: "created_at",
      label: "新增日期",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          moment(value).format("YYYY-MM-DD hh:mm:ss"),
      },
    },
    {
      name: "id",
      label: "操作",
      options: {
        filter: false,
        sort: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex) => {
          const val = data[dataIndex]
          return (
            <div className="flex justify-center gap-4 align-center e">
              {ownPermissionList.includes("SETTING_EVENT_UPDATE") && (
                <button
                  onClick={() => {
                    navigate(`/setting/event/${val.id}/edit`)
                  }}
                  className="w-20 px-3 py-1 font-bold text-white bg-blue-400 rounded hover:bg-blue-300 borde align-center"
                >
                  編輯
                </button>
              )}
            </div>
          )
        },
      },
    },
  ]

  return (
    <>
      <h1 className="my-3 text-2xl font-bold">警示設定</h1>
      {/* BreadCrumb */}
      <BreadCrumb data={breadcrumbs} />

      <div className="flex justify-end mb-4">
        {ownPermissionList.includes("SETTING_EVENT_CREATE") && (
          <AddButton pageNavigate={navigateToAdd} />
        )}
      </div>

      <MUIDataTable data={data} columns={columns} options={options} />
    </>
  )
}

export default Event
