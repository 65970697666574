import { TextField, Typography } from "@mui/material"
import BreadCrumb from "components/BreadCrumb"
import { VendorMetrics } from "components/predict-model/VendorMetrics"
import { getVendor } from "features/utils"
import { getVendorAreas, getVendorMetrics } from "features/utils/vendor"
import { nanoid } from "nanoid"
import { useCallback, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { DatePicker } from "components/DatePicker"
import { createPredictModel, getPredictionById, updatePredictModel } from "features/utils/predict-model"
import { PredictForm } from "./PredictForm"
import { TrainForm } from "./TrainForm"

export function EditTrain() {
    const [defaultFormValue, setDefaultFormValue] = useState({
        devices: [],
        features: [],
        targets: [],
    })
    const navigate = useNavigate()
    const { id } = useParams();

    const breadcrumbs = [
        <Typography key="1" color="text.primary">
            AI 預測模型
        </Typography>,
        <Typography key="2" color="text.primary">
            <Link to="/predict/train-model">訓練模式</Link>
        </Typography>,
        <Typography key="2" color="text.secondary">
            編輯訓練模式
        </Typography>,
    ]

    const handleFetchPrediction = useCallback(() => {
        getPredictionById(id).then(resp => {
            const { data } = resp
            const newValue = {
                vendor: data.vendor,
                name: data.name,
                model: data.model,
                pca: data.pca !== 0,
                begin_time: data.begin_time,
                end_time: data.end_time,
                devices: data.devices.map(v => v.device_id.toString()),
                features: data.features.map(v => v.name),
                targets: data.targets.map(v => v.name),
                training_set: data.training_set,
                test_set: data.test_set,
            }
            setDefaultFormValue(newValue)
        }).catch(e => toast.error('抓取資料失敗'))
    }, [])

    const onSubmit = (data, e) => {
        updatePredictModel(id, data).then(resp => {
            toast.success('編輯成功')
            handleFetchPrediction();
        }).catch(e => {
            toast.error(e.error)
        })
    }

    useEffect(() => {
        handleFetchPrediction()
    }, [handleFetchPrediction])

    return (
        <TrainForm handleOnSubmit={onSubmit} breadcrumbs={breadcrumbs} defaultValues={defaultFormValue} />
    )
}