import { Typography } from "@mui/material"
import { createPredictModel } from "features/utils/predict-model"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { PredictForm } from "./PredictForm"

export function CreatePredict() {
    const navigate = useNavigate()

    const defaultValues = {
        devices: [],
        features: [],
        targets: [],
    }

    const onSubmit = (data, e) => {
        createPredictModel(data).then(resp => {
            toast.success('新增成功')
            navigate('/predict/predict-model')
        }).catch(e => {
            toast.error(e.error)
        })
    }

    const breadcrumbs = [
        <Typography key="1" color="text.primary">
            AI 預測模型
        </Typography>,
        <Typography key="2" color="text.primary">
            <Link to="/predict/predict-model">預測模式</Link>
        </Typography>,
        <Typography key="2" color="text.secondary">
            新增預測模式
        </Typography>,
    ]

    return (
        <PredictForm handleOnSubmit={onSubmit} breadcrumbs={breadcrumbs} defaultValues={defaultValues} />
    )
}