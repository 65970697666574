import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'

function SelectOptionV2({ data, control, Controller, setValue, titleName, label = "", defaultValues = "" }) {
    const [selectedIds, setSelectedIds] = useState(defaultValues);
    const [selectAll, setSelectAll] = useState(false);

    const handleCheckboxChange = (checked, id) => {
        let newSelectedIds;
        if (checked) {
            newSelectedIds = [...selectedIds, id];
        } else {
            newSelectedIds = selectedIds.filter(item => item !== id);
        }
        setSelectedIds(newSelectedIds);

        if (newSelectedIds.length === data.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }

    const handleSelectAllChange = (checked) => {
        setSelectAll(checked);
        if (checked) {
            setSelectedIds(data?.map(v => v.id));
        } else {
            setSelectedIds([]);
        }
    }

    const determineName = (label, i) => label === "" ? `permissions[${i}]` : `area_ids[${i}]`;

    useEffect(() => {
        setValue(label === "" ? "permissions" : "area_ids", selectedIds);
    }, [selectedIds]);

    useEffect(() => {
        if (defaultValues) {
            setSelectedIds(defaultValues);
            if (data && defaultValues.length === data.length) {
                setSelectAll(true);
            } else {
                setSelectAll(false);
            }
        }
    }, [defaultValues, data]);

    return (
        <>
            <h1 className="font-medium mt-4 mb-2">{titleName}</h1>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={selectAll}
                        onChange={(e) => handleSelectAllChange(e.target.checked)}
                    />
                }
                label="全選"
            />
            <FormGroup className="!flex-row">
                {data?.map((v, i) => (
                    <FormControlLabel
                        className="w-1/5"
                        key={v.name}
                        label={label === "" ? v.description : v.name}
                        control={
                            <Controller
                                name={determineName(label, i)}
                                control={control}
                                render={({ field }) => (
                                    <Checkbox
                                        checked={selectedIds.includes(v.id)}
                                        onChange={e => {
                                            field.onChange(e.target.checked);
                                            handleCheckboxChange(e.target.checked, v.id);
                                        }}
                                    />
                                )}
                            />
                        }
                    />
                ))}
            </FormGroup>
        </>
    )
}

export default SelectOptionV2
