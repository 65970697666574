import TextField from "@mui/material/TextField"
import { useForm, Controller } from "react-hook-form"
import { Radio, FormControlLabel, RadioGroup } from "@mui/material"
import { useEffect, useState } from "react"
import FishTypeRadio from "./components/FishTypeRadio"
import { RedioTextField } from "./components/RedioTextField"
import MapContent from "./components/MapContent"

function AreaForm({ values, onSubmit }) {
  const [address, setAddress] = useState("")
  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({ defaultValues: values })

  useEffect(() => {
    setAddress(`${values.city}${values.district}${values.address}`)
    reset(values)
  }, [values])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form">
      <div className="flex">
        <div className="w-1/2">
          <Controller
            render={({ field }) => (
              <TextField
                className="w-60"
                label="*場名"
                error={!!errors.name}
                variant="outlined"
                {...field}
              />
            )}
            name="name"
            control={control}
            rules={{ required: true }}
          />

          <Controller
            render={({ field }) => (
              <div className="mt-8">
                <TextField className="w-11/12" label="描述" variant="outlined" {...field} />
              </div>
            )}
            name="description"
            control={control}
          />

          {/* <div className="flex justify-start gap-6"> */}
          <Controller
            render={({ field }) => (
              <div className="mt-8">
                <TextField
                  className="w-60 inline-block"
                  label="*負責人"
                  variant="outlined"
                  error={!!errors.owner}
                  {...field}
                />
              </div>
            )}
            name="owner"
            control={control}
            rules={{ required: true }}
          />
          <Controller
            render={({ field }) => (
              <div className="mt-8">
                <TextField
                  className="w-60 inline-block"
                  label="歸屬漁會"
                  variant="outlined"
                  {...field}
                />
              </div>
            )}
            name="club"
            control={control}
          />
          {/* </div> */}

          <p className="mt-8 font-medium">養殖魚種品項：</p>

          <div className="mt-5">
            <FishTypeRadio
              control={control}
              setValue={setValue}
              getValues={getValues}
              radioValue={0}
              label="專養"
              name="fish_special"
            />
            <FishTypeRadio
              control={control}
              setValue={setValue}
              getValues={getValues}
              radioValue={1}
              label="混養"
              name="fish_mix"
            />
          </div>

          <p className="mt-5 mb-2 font-medium">養殖階段類型：</p>
          <Controller
            render={({ field }) => (
              <RadioGroup row {...field}>
                <div className="w-full ml-10">
                  <div className="inline-block mr-4 w-24 font-bold">水產種苗</div>
                  <FormControlLabel value="0" control={<Radio />} label="種魚培育" />
                  <FormControlLabel value="1" control={<Radio />} label="仔魚育成:白身苗/寸苗" />
                  <FormControlLabel value="2" control={<Radio />} label="中間育成" />
                </div>
                <div className="w-full ml-10">
                  <div className="inline-block mr-4 w-24 font-bold">養成</div>
                  <FormControlLabel value="3" control={<Radio />} label="成魚養殖" />
                </div>
                <div className="w-full ml-10">
                  <div className="inline-block mr-4 w-24 font-bold">其他</div>
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label={
                      <RedioTextField
                        checked={field.value === "4" || field.value.toString() === "4"}
                        label="其他"
                        control={control}
                        name="other_stage"
                      />
                    }
                  />
                </div>
              </RadioGroup>
            )}
            name="stage"
            control={control}
          />

          <p className="mt-5 mb-2 font-medium">養殖戶的設施類型：</p>
          <Controller
            render={({ field }) => (
              <RadioGroup {...field}>
                <div className="w-full ml-10">
                  <FormControlLabel value="0" control={<Radio />} label="傳統魚塭養殖" />
                  <FormControlLabel value="1" control={<Radio />} label="室外設施養殖" />
                  <FormControlLabel value="2" control={<Radio />} label="室內設施養殖" />
                  <FormControlLabel value="3" control={<Radio />} label="室內循環水養殖" />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label={
                      <RedioTextField
                        checked={field.value === "4" || field.value.toString() === "4"}
                        label="其他"
                        control={control}
                        name="other_fishpond"
                      />
                    }
                  />
                </div>
              </RadioGroup>
            )}
            name="fishpond"
            control={control}
          />

          <p className="mt-5 mb-2 font-medium">養殖池結構類型：</p>
          <Controller
            render={({ field }) => (
              <RadioGroup {...field}>
                <div className="w-full ml-10">
                  <FormControlLabel value="0" control={<Radio />} label="室外土池" />
                  <FormControlLabel value="1" control={<Radio />} label="室外RC池" />
                  <FormControlLabel value="2" control={<Radio />} label="室外FRP池" />
                  <FormControlLabel value="3" control={<Radio />} label="室內RC池" />
                  <FormControlLabel value="4" control={<Radio />} label="室內FRP池" />
                </div>
              </RadioGroup>
            )}
            name="structure"
            control={control}
          />

          <p className="mt-5 mb-2 font-medium">養殖面積:</p>
          <div className="mt-5 ml-4 flex justify-start">
            <Controller
              render={({ field }) => (
                <div>
                  平方公尺
                  <TextField className="w-20 inline-block" label="" variant="standard" {...field} />
                </div>
              )}
              name="area"
              control={control}
            />
            <p className="mx-6"> / </p>
            <Controller
              render={({ field }) => (
                <div>
                  平均養殖水量
                  <TextField className="w-20 inline-block" label="" variant="standard" {...field} />
                </div>
              )}
              name="water_volume"
              control={control}
            />
            <p className="mx-6"> / </p>
            <Controller
              render={({ field }) => (
                <div>
                  平均深度
                  <TextField className="w-20 inline-block" label="" variant="standard" {...field} />
                </div>
              )}
              name="depth"
              control={control}
            />
          </div>

          <div className="flex mt-5 mb-2 justify-start">
            <p className="font-medium w-36">年度平均放養量:</p>
            <Controller
              render={({ field }) => (
                <TextField className="ml-10" label="" variant="standard" {...field} />
              )}
              name="bred_amount"
              control={control}
            />
          </div>

          <div className="flex mt-5 mb-2 justify-start">
            <p className="font-medium w-36">平均產能:</p>
            <Controller
              render={({ field }) => (
                <TextField className="ml-4" label="" variant="standard" {...field} />
              )}
              name="capacity"
              control={control}
            />
          </div>
        </div>
        <div className="w-1/2">
          <MapContent
            control={control}
            address={address}
            getValues={getValues}
            setValue={setValue}
          />
        </div>
      </div>
      <button
        type="submit"
        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary-600 rounded-md hover:bg-primary-300 focus:outline-none focus:bg-primary-300 mt-12"
      >
        送出
      </button>
    </form>
  )
}

export default AreaForm
