import { useState } from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default function DateAndTimePicker(props) {
    const { currentDate, handleChange } = props

    const [selectTime, setSelectTime] = useState(dayjs(currentDate));

    const fillTen = (time) => {
        let realNum;
        if (time < 10) {
            realNum = `0${time}`;
        } else {
            realNum = time;
        }
        return realNum;
    }

    const formateDate = (time) => {
        const formateValue = `${time.$y}-${fillTen(time.$M + 1)}-${fillTen(time.$D)} ${fillTen(time.$H)}:${fillTen(time.$m)}:00`
        return formateValue
    }

    return (
        <section className="dateAndTimePicker">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    value={selectTime}
                    onChange={(date) => {
                        setSelectTime(date);
                        handleChange(formateDate(date))
                    }}
                />
            </LocalizationProvider>
        </section>
    );
}
