import { TextField } from "@mui/material"
import { Controller } from "react-hook-form"

function WeatherAngel(props) {
  const { control } = props

  return (
    <div className="mt-8">
      <Controller
        render={({ field }) => (
          <TextField {...field} fullWidth variant="outlined" label="Station ID" />
        )}
        name="data.station_id"
        control={control}
        defaultValue=""
      />
    </div>
  )
}

export default WeatherAngel
