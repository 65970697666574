import TextField from "@mui/material/TextField"

function ManagerAccountForm({ managerInfo = "", onChange, handleClickOpen }) {
  return (
    <form className="mt-8" onSubmit={handleClickOpen}>
      <div className="mt-4">
        <TextField
          className="w-full"
          label="管理員帳號"
          variant="outlined"
          onChange={onChange}
          name="username"
          disabled
          defaultValue={managerInfo?.username}
        />
      </div>
      <div className="mt-4">
        <TextField
          className="w-full"
          label="新密碼"
          type="password"
          variant="outlined"
          onChange={onChange}
          name="password"
          required
          autoComplete="off"
        />
      </div>
      <div className="mt-4">
        <TextField
          className="w-full"
          label="再次確認新密碼"
          type="password"
          variant="outlined"
          onChange={onChange}
          name="confirmationPassword"
          required
          autoComplete="off"
        />
      </div>
      <button
        type="submit"
        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary-600 rounded-md hover:bg-primary-300 focus:outline-none focus:bg-primary-300 mt-12"
      >
        送出
      </button>
    </form>
  )
}

export default ManagerAccountForm
