import React from "react"
import { useNavigate } from "react-router-dom"

/**
 * @name EditButton
 * @param {string} link
 * @param {string} content
 * @returns {function} EditButton
 */

function EditButton({ link, content }) {
  const navigate = useNavigate()

  return (
    <button
      onClick={() => {
        navigate(link)
      }}
      className="bg-gray-400 hover:bg-gray-300 text-white font-bold py-1 px-3 borde rounded flex align-center w-20"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 mr-1"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
      </svg>
      {content}
    </button>
  )
}

export default EditButton
