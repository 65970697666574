import MUIDataTable from "mui-datatables"
import AddButton from "components/button/AddButton"
import { Link, useNavigate } from "react-router-dom"
import { useQuery, useMutation } from "react-query"
import { getAreaSpecific, deleteDevice } from "features/utils"
import { useState, useEffect } from "react"
import ChartButton from "components/button/ChartButton"
import Spinner from "components/Spinner"
import { toast } from "react-toastify"
import CustomDialog from "components/dialog/CustomDialog"
import EditButton from "components/button/EditButton"

function Device({ areaId }) {
  // Get Device
  const { isFetching, isError, isLoading, error, data, refetch } = useQuery(
    "getDevice",
    () => getAreaSpecific(areaId),
    {
      cacheTime: 0,
    }
  )

  const [device, setDevice] = useState(null)

  useEffect(() => {
    setDevice(data?.data?.devices)
  }, [isFetching])

  // Delete Device
  const [deleteId, setDeleteId] = useState({ ids: [] })
  const { mutate } = useMutation(() => deleteDevice(deleteId), {
    onSuccess: () => {
      refetch()
      toast.success("刪除成功")
    },
    onError: (error) => {
      toast.error(error)
    },
  })

  /* MUI Table */
  const columns = ["ID", "裝置", "裝置名稱", "新增時間", "操作"]
  const deviceData = device?.map((device) => {
    /* Porcess Time */
    const time = new Date(device?.created_at).toLocaleString("zh-tw")

    /* Return Table */
    return [
      device?.id,
      device?.vendor_info?.label,
      device?.name,
      time,
      <div className="flex gap-4">
        <ChartButton link={`${areaId}/edit/device/${device?.id}/chart`} content="圖表" />
        <EditButton content="修改" link={`device/${device?.id}/edit`} />
      </div>,
    ]
  })

  const deleteRow = () => {
    mutate(deleteId)
    setOpen(false)
    setDeleteId({ ids: [] })
  }

  const options = {
    responsive: "standard",
    filterType: "checkbox",
    print: false,
    download: false,
    selectableRowsOnClick: true,
    onRowsDelete: (rowsDeleted) => {
      handleClickOpen(rowsDeleted)
    },
  }

  // Dialog
  const [open, setOpen] = useState(false)

  const handleClickOpen = (deleteId) => {
    setOpen(true)
    const indexes = deleteId.data.map((x) => x.dataIndex)
    const ids = []
    indexes.forEach((i) => {
      ids.push(deviceData[i][0])
    })
    const result = { ids }
    setDeleteId(result)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // Add Button Navigate
  const navigate = useNavigate()
  const navigateToAddDevice = () => {
    navigate(`/setting/area/${areaId}/edit/device`)
  }

  // Status
  if (isLoading) {
    return <Spinner />
  }

  if (isError) {
    toast.error(error.message)
  }

  return (
    <>
      <CustomDialog open={open} method={deleteRow} handleClose={handleClose} content="刪除" />
      {/* Add Button */}
      <div className="flex justify-end">
        <AddButton pageNavigate={navigateToAddDevice} text="感測器" />
      </div>

      <MUIDataTable title="裝置列表" data={deviceData} columns={columns} options={options} />
    </>
  )
}

export default Device
