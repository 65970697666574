import { getVendor } from "features/utils"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"

function useVendorData() {
  const { isLoading, isError, error, isFetching, data } = useQuery("getVendor", () => getVendor())

  const [vendorLabel, setVendorLabel] = useState(null)

  useEffect(() => {
    setVendorLabel(data?.data)
  }, [data])

  return [vendorLabel, setVendorLabel, isLoading, isError, error, isFetching]
}

export default useVendorData
